import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from "react-router-dom";
import LoanExpectedRightPanel from './LoanExpectedRightPanel'
import { useSelector, useDispatch } from 'react-redux';
import { gql, useApolloClient } from '@apollo/client';
import { executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import { getLoanDetail, getMasterDataMMV } from '../../store/action/allAction';
import { toast } from "react-toastify";
import FormFields from './formFields/loanExpected.json';
import Formsy from 'formsy-react';
import FormsyInputField from '../elements/FormsyInputField';
import FormsySelect from '../elements/FormsySelect';
import { addValidationRule } from 'formsy-react';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE, TRUCK_IDS} from '../../../src/config/constants';
import generalService from '../../services/generalService';
import crypto from '../../config/crypto'
import { Nav, Tab } from 'react-bootstrap';
import { TENURE_IN_MONTH } from '../../config/constants';
import LoanQuoteTabs from './LoanQuoteTabs';

const DPType = [
    { "id": 1, "label": "%age", "value": 1 },
    { "id": 2, "label": "Amount", "value": 2 }
]

const LoanExpectedBasicDetails = FormFields.loan_expected_basic_details || [];
const LoanExpectedCustomerDetails = FormFields.loan_expected_customer_details || [];
const LoanExpectedDealerDetails = FormFields.loan_expected_dealer_details || [];
const LoanExpectedCarmudiDetails = FormFields.loan_expected_carmudi_details || [];
const LoanExpectedEditView = FormFields.loan_expected_edit_view || [];

const LoanExpected = (props) => {
    const client = useApolloClient()
    const dispatch = useDispatch();
    let param = useParams();
    const lead_id = crypto.decode(param.lead_id)
    const [displayBlock, setDisplayBlock] = useState('SUMMARY');
    const [EditView, setEditView] = useState(false);
    const [ApproveView, setApproveView] = useState(false);
    const [FinancerEmail, setFinancerEmail] = useState(false);
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [editLoanExpectedData, setEditLoanExpectedData] = useState([]);
    const [summaryDetails, setSummaryDetails] = useState([]);
    const [vehicleType, setVehicleType] = useState(0);
    const [requiredDpType, setRequiredDpType] = useState({});
    const [allOptions, setAllOptions] = useState({});
    const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);
    const [editButtonCondition, setEditButtonCondition] = useState(false);
    const [activeTab, setActiveTab] = useState('finance-0')
    const [currentFinancer,setCurrentFinancer]=useState(null);
    // const [summaryDetailsArray, setSummaryDetailsArray] = useState([]);
    // const [editLoanExpectedDataArray, setEditLoanExpectedDataArray] = useState([]);
    const [disabledApproved,setdisabledApproved]=useState(false);
    const [showChattelDD, setShowChattelDD] = useState(false);
    const [quoteDatainfo, setquoteData] = useState(false);
    const [displayQuote, setDisplayQuote] = useState(false);
    let accessCondition = !IS_USER_ACCESS_PAGE("loan_expected", "loan_expected").is_edit_access

    const { masterdata, leadDetail, remarksHistory } = useSelector(({masterdata, lead}) => ({
        masterdata,
        leadDetail: lead.leadDetail,
        remarksHistory:lead.remarksHistory || []
    }));

    if(!accessCondition){
        accessCondition=CHECK_ASSIGN_USER(leadDetail?.assign_to);
    }

    const loanQuote = (leadDetail?.quote_data?.length && leadDetail?.quote_data?.filter(quote => ![0, null, ""].includes(quote.financier_id))) || [];

    useEffect(() => {
        const fetchData = async () => {
            if(props.tab_name === "second"){
                if(leadDetail && leadDetail.lead_status_history){
                    let send_to_financier_status = leadDetail.lead_status_history.find((history, index) => history.sub_status_id === 3) || {};
                    if(Object.keys(send_to_financier_status).length > 0 || leadDetail.status_id === 5)
                        setEditButtonCondition(true);
                    else
                        setEditButtonCondition(false);
                }
                let current_financier = currentFinancer || leadDetail?.financier?.[0]?.financier_id || 0;
                setCurrentFinancer(current_financier);
                if(leadDetail) await getLeadDetailInput(current_financier);
            }
        };
        fetchData();
        leadDetail?.quote_data?.forEach((quote)=>{
            if(leadDetail?.financier?.[0]?.financier_id===quote.financier_id)
                get_master_data(quote?.vehicle_type);
        })
    }, [leadDetail,props]);

    const get_master_data = async (vehicle_type) => {

        let category_id=1;
        if(TRUCK_IDS.includes(Number(vehicle_type))){
            category_id=3;
        }
        if(vehicleType!==category_id){
            dispatch(getMasterDataMMV(client, vehicle_type));
            getMasterDataOptions();
        }
            setVehicleType(category_id)

        if(vehicle_type?.toString()==='6'){

            LoanExpectedEditView.forEach((fileds,key)=>{
                if(fileds.id==="brand" || fileds.id==="model" || fileds.id==="variant")
                LoanExpectedEditView[key].type="text";
            })
        }else{
            LoanExpectedEditView.forEach((fileds,key)=>{
                if(fileds.id==="brand" || fileds.id==="model" || fileds.id==="variant")
                LoanExpectedEditView[key].type="dropdown";
            })
        }
    }

    useEffect(()=>{
        getMasterDataOptions();
    },[masterdata])

    // useEffect(() => {
    //     const fetchData = async () => {
    //         if(props.tab_name == "second"){
    //             if(leadDetail) await getLeadDetailInput(); 
    //             if(masterdata) await getMasterDataOptions();
    //         }
    //     };
    //     fetchData();
    // }, [leadDetail]);

    const onTabChange = async(tab, event) => {
      /** Adding sorting along with tab change */
      if (event.type === "click") { // Only change on tab click
        setDisplayBlock("SUMMARY");
        setEditView(false);
            setCurrentFinancer(event?.target?.attributes?.financeid?.value);
            let quote=leadDetail?.quote_data?.filter((quote)=>quote.financier_id===Number(event?.target?.attributes?.financeid?.value));
            await get_master_data(quote?.[0]?.vehicle_type)
            await getLeadDetailInput(event?.target?.attributes?.financeid?.value)
            if(quote?.[0]?.vehicle_type === 6){

                LoanExpectedEditView.forEach((fileds,key)=>{
                    if(fileds.id==="brand" || fileds.id==="model" || fileds.id==="variant")
                    LoanExpectedEditView[key].type="text";
                })
            }
         setActiveTab(tab);
         setRequiredDpType({});
      }
    }

    const getLeadDetailInput = async(finance_id=0) => {
        if(leadDetail && leadDetail.quote_data && leadDetail.quote_data.length && leadDetail.lead_details){
            let editdetailsArray=[],summaryDetailsArray=[];
            leadDetail.quote_data.map(async (quote,key)=>{
            let summaryDetails = {
                is_active: true,
                min_dp: quote?.dp_value || 0,
                downpayment: quote?.dp_amount || 0,
                tenure: quote?.tenure_in_month || 0,
                installment_amount: quote?.installment_amount || 0,
                interest_rate: quote?.interest_rate,
                loan_amount: quote?.loan_amount || 0,
                chattel_percent: quote?.chattel_percent,
                chattel_fee: quote?.chattel_fee || 0,
                outoftowncharge: quote?.out_of_town_charge || 0,
                dealer_incentive_percent: quote?.dealer_incentive_percent,
                dealer_incentive: quote?.dealer_incentive || 0,
                processing_fee: quote?.processing_fee,
                carmudi_top_up_percent: quote?.carmudi_top_up_percent
            };
                summaryDetails = await handleDetailChange(summaryDetails);
                summaryDetailsArray.push(summaryDetails);

            let edit_details = {
                brand: leadDetail?.lead_details?.make_id,
                model: leadDetail?.lead_details?.model_id,
                variant: leadDetail?.lead_details?.variant_id,

                make_name: leadDetail?.lead_details?.make_name,
                model_name: leadDetail?.lead_details?.model_name,
                variant_name: leadDetail?.lead_details?.variant_name,

                yearofmanufacturing: leadDetail?.lead_details?.make_year || 0,
                tenure: quote?.tenure_in_month || 0,
                vehicle_usage: editLoanExpectedData?.vehicle_usage || quote?.vehicle_usage || 0,
                vehicle_type: editLoanExpectedData?.vehicle_type || quote?.vehicle_type || 0,
                other_vehicle_type : quote?.other_vehicle_type || 0,
                car_fmv : quote?.car_fmv || 0,
                out_of_town_charges : quote?.out_of_town_charge || 0,
                dp_type : quote?.dp_type === "per" ? 1 : 2 || 0,
                dp_value : quote?.dp_value| 0,
                dp_amount: quote?.dp_amount || 0,
                chattel_percent: summaryDetails.chattel_percent || 0,
                financier_id : quote?.financier_id || 0,
                interest_rate: summaryDetails?.interest_rate || 0
            }
                editdetailsArray.push(edit_details);
                
                if(Number(leadDetail?.financier?.[0]?.financier_id)===Number(quote?.financier_id) && Number(finance_id)===0 && !currentFinancer){
                    setSummaryDetails([summaryDetails]);
                    setEditLoanExpectedData(prev =>({...prev, ...edit_details}));
                }
                else if(Number(finance_id)!==0 && Number(finance_id)===Number(quote?.financier_id)){
                    setSummaryDetails([summaryDetails]);
                    setEditLoanExpectedData(prev =>({...prev, ...edit_details}));
                }
            })
            // setSummaryDetailsArray(summaryDetailsArray);
            // setEditLoanExpectedDataArray(editdetailsArray);
            
        }
    }

    const getMasterDataOptions = async() => {
        let options = masterdata ? {...masterdata.masterdatammv, ...masterdata.data,chattel_percent: allOptions?.chattel_percent || []} : {};
        options["brand"] =  options.make?.map(make => ({value: make.id,label: make.make})) ;
        options["model"] = leadDetail?.lead_details?.make_id ? options.model?.map(model => ({value: model.id,label: model.m, mk_id: model.mk_id})).filter(md => md.mk_id === leadDetail?.lead_details?.make_id) : options.model?.map(model => ({value: model.id,label: model.m, mk_id: model.mk_id}));
        options["variant"] = leadDetail?.lead_details?.model_id ? options.version?.map(version =>({value : version.vn_id,label: version.vn, mk_id: version.mk_id, md_id: version.md_id})).filter(v => v.md_id === leadDetail?.lead_details?.model_id) : options.version?.map(version =>({value : version.vn_id,label: version.vn, mk_id: version.mk_id, md_id: version.md_id}));
        options["yearofmanufacturing"] = masterdata?.data?.make_year;
        options["tenure"] = TENURE_IN_MONTH[leadDetail?.financier_id] || TENURE_IN_MONTH['0'];
        options.brand?.push({value: 0,label:'Other'});
        options.model?.push({value: 0,label:'Other'});
        options.variant?.push({value: 0,label:'Other'});
        setAllOptions(prev=>({...prev, ...options}));
    }

    //CALCULATED VALUES FUNCTION
    const handleDetailChange = (summaryDetails) => {
        const totalPaymentByCustomer =
              (summaryDetails.chattel_fee ?? 0) + (summaryDetails.outoftowncharge ?? 0) + (summaryDetails.downpayment ?? 0);
            const dealerIncentiveFromFinancer =
              (summaryDetails.loan_amount ?? 0) * (summaryDetails.dealer_incentive_percent/100 ?? 0);
            const dealerIncentiveFromCarmudi =
              (summaryDetails.loan_amount ?? 0) * (summaryDetails.carmudi_top_up_percent/100 ?? 0);
            const totalDealerIncentive = dealerIncentiveFromCarmudi + dealerIncentiveFromFinancer;
            const dealer_incentive_tax_percent = 10;
            const total_dealer_incentive_net_tax =
              (totalDealerIncentive ?? 0) * (1 - dealer_incentive_tax_percent/100);
            const total_payment_to_dealer =
              (summaryDetails.loan_amount ?? 0) + (total_dealer_incentive_net_tax ?? 0);
            const dealerIncentiveFromFinancerNetofTax = (dealerIncentiveFromFinancer ?? 0) * (1- dealer_incentive_tax_percent/100);
            const total_payment_from_financier =
              (summaryDetails.loan_amount ?? 0) + dealerIncentiveFromFinancerNetofTax;
            summaryDetails={
                ...summaryDetails,
                totalPaymentByCustomer: leadDetail?.quote_data?.total_payment_by_customer ? leadDetail?.quote_data?.total_payment_by_customer : totalPaymentByCustomer,
                dealerIncentiveFromFinancer: leadDetail?.quote_data?.dealer_incentive_from_financier ? leadDetail?.quote_data?.dealer_incentive_from_financier : dealerIncentiveFromFinancer,
                dealerIncentiveFromCarmudi: leadDetail?.quote_data?.dealer_incentive_from_carmudi ? leadDetail?.quote_data?.dealer_incentive_from_carmudi : dealerIncentiveFromCarmudi,
                totalDealerIncentive: leadDetail?.quote_data?.total_dealer_incentive ? leadDetail?.quote_data?.total_dealer_incentive : totalDealerIncentive,
                dealerIncentiveTaxPercent: leadDetail?.quote_data?.dealer_incentive_tax_percentage ? leadDetail?.quote_data?.dealer_incentive_tax_percentage : dealer_incentive_tax_percent,
                totalDealerIncentiveNetTax: leadDetail?.quote_data?.total_dealer_incentive_net_of_tax ? leadDetail?.quote_data?.total_dealer_incentive_net_of_tax : total_dealer_incentive_net_tax,
                totalPaymentfromFinancer: leadDetail?.quote_data?.total_payment_from_financier ? leadDetail?.quote_data?.total_payment_from_financier : total_payment_from_financier,
                totalPaymentToDealer: leadDetail?.quote_data?.total_payment_to_dealer ? leadDetail?.quote_data?.total_payment_to_dealer : total_payment_to_dealer,
                dealerIncentiveFromFinancerNetofTax : leadDetail?.quote_data?.dealer_incentive_from_financier_net_of_tax ? leadDetail?.quote_data?.dealer_incentive_from_financier_net_of_tax : dealerIncentiveFromFinancerNetofTax
            }
        return summaryDetails;
    } 

    const getModel = (make_id) => {
        if(make_id){
            let options = masterdata ? {...masterdata.masterdatammv} : {};
            options["model"] = options.model?.map(model => ({value: model.id,label: model.m, mk_id: model.mk_id})).filter(md => md.mk_id === make_id);
            options.model?.push({value: 0,label:'Other'});
            let all_options = {...allOptions};
            all_options["model"] = options["model"];
            setAllOptions(all_options)
        }
    }

    const getVersion = (model_id) => {
        if(model_id){
            let options = masterdata ? {...masterdata.masterdatammv} : {};
            options["variant"] = options.version?.map(version =>({value : version.vn_id,label: version.vn, mk_id: version.mk_id, md_id: version.md_id})).filter(v => v.md_id === model_id);
            options.variant?.push({value: 0,label:'Other'});
            let all_options = {...allOptions};
            all_options["variant"] = options["variant"];
            setAllOptions(all_options)
        }
    }

    const getMake = (vehicle_type) => {
        if(vehicle_type){
            setShowChattelDD(false)
            let vehicle_category_id = TRUCK_IDS.includes(leadDetail?.quote_data?.vehicle_type) ? 3 : 1;
            let input_category_id = TRUCK_IDS.includes(+vehicle_type) ? 3 : 1;
            if(vehicle_category_id !== input_category_id) {
                setShowChattelDD(true)
            }
            get_master_data(vehicle_type);
        }
    }

    const handleMakeChange = (sname, ovalue) => {
        editLoanExpectedData[sname] = ovalue.value;
        if (sname === 'brand') { 
            getModel(ovalue.value); 
            editLoanExpectedData['model'] = null; 
            editLoanExpectedData['variant'] = null;
        } 
    else if (sname === 'model') { getVersion(ovalue.value); editLoanExpectedData['variant'] = null;}
        else if(sname === 'vehicle_type'){
            getMake(ovalue.value);
            if(Number(ovalue.value)===6){
                editLoanExpectedData['model'] =  ''; 
            editLoanExpectedData['brand'] =  ''; 
            editLoanExpectedData['variant'] =  '';
            editLoanExpectedData['other_vehicle_type'] =  '';
            }else{
            editLoanExpectedData['model'] =  null; 
            editLoanExpectedData['brand'] =  null; 
            editLoanExpectedData['variant'] =  null;
            }
            editLoanExpectedData[sname] = parseInt(ovalue.value);
        } 
        else if(sname === 'vehicle_usage'){
            setVehicleTypeOptions(ovalue?.vehicle_type || [])
            editLoanExpectedData['vehicle_type'] = null;
            if(Number(ovalue.value)===6){
                editLoanExpectedData['model'] =  ''; 
            editLoanExpectedData['brand'] =  ''; 
            editLoanExpectedData['variant'] =  '';
            editLoanExpectedData['other_vehicle_type'] =  '';
            }else{
            editLoanExpectedData['model'] =  null; 
            editLoanExpectedData['brand'] =  null; 
            editLoanExpectedData['variant'] =  null;
            }
            editLoanExpectedData[sname] = parseInt(ovalue.value);
        } 
        setEditLoanExpectedData({ ...editLoanExpectedData });
    }
    useEffect(() => {
        setVehicleTypeOptions(masterdata?.masterdatammv?.vehicle_usage?.find(el=> +el.id === editLoanExpectedData?.vehicle_usage)?.vehicle_type || [])
    },[editLoanExpectedData?.vehicle_usage])
  
    // const showModalFinancerEmail = () => {
    //   setFinancerEmail(true);
    //   document.body.classList.add("overflow-hidden");
    // };
  
    const hideModalFinancerEmail = () => {
      setFinancerEmail(false);
      document.body.classList.remove("overflow-hidden");
    };
  
    const toggleswitch = async(type, btn_type) => {
        setDisplayBlock(type);
        if(type === 'EDITVIEW') {
            calculateLoan("calculate",'EDITVIEW');
        }
    };

    const handleButtonChange = (index) => {
        let summary_details = [...summaryDetails];
        let updatedSummaryDetails = summary_details.map((obj, i) => ({
            ...obj,
            is_active: i === index ? true : false
        }));
        setSummaryDetails(updatedSummaryDetails);
    }

    const handleInputChange = (target_id, event, type, edit_type) => {
        let value = type === "number-format" ? event.floatValue : event.target.value;
        if (target_id && value && edit_type === "edit_view") {
            setEditLoanExpectedData(currentValue => ({
                ...currentValue,
                [target_id]: value
            }))
        }else if (target_id && value && edit_type === "summary") {
            setRequiredDpType(currentValue => ({
                ...currentValue,
                [target_id]: value
            }))
        } 
        else if(document.getElementById("required_dp_amount")?.value === '') {
            setRequiredDpType(currentValue => ({
                ...currentValue,
                [target_id]: 0
            }))
        }
        if(value && target_id === "car_fmv" && edit_type === "edit_view" && editLoanExpectedData?.dp_type === 1)
            handleDpAmountChange(value, target_id);                
    }

    const handleSelectChange = async(data, field_name, type) => {
        if (data && field_name && type === "edit_view" && field_name !== "dp_value") {
            setEditLoanExpectedData(currentValue => ({
                ...currentValue,
                [field_name]: ['chattel_percent', 'interest_rate'].includes(field_name) ? parseFloat(data.value) : parseInt(data.value)
            }))
            if(['tenure','yearofmanufacturing'].includes(field_name)) {
                setShowChattelDD(true)
            }
            if(field_name === "chattel_percent")
                await handleChattelPercentChange(parseFloat(data.value));
        }else if(data && field_name && type === "summary"){
            setRequiredDpType(currentValue => ({
                ...currentValue,
                [field_name]: parseInt(data.value)
            }))
        }

        if(data && field_name === "dp_value" && type === "edit_view")
            handleDpAmountChange(parseInt(data.value), field_name);
    }

    const handleChattelPercentChange = (value) =>{
        let interest_rate =  Array.from(new Set(quoteDatainfo?.[0]?.chattel_fee_info?.filter(quote => quote.chattel_percent === value)?.map(data => data.interest_rate))).map(rate => ({ label: rate, value: rate })) || [];
        setAllOptions({...allOptions, interest_rate: interest_rate});
        setEditLoanExpectedData({
            ...editLoanExpectedData,
            interest_rate: interest_rate?.[0]?.value,
            chattel_percent: value || 0
        })
    }

    const handleDpAmountChange = (data, field_name) => {
        let car_fmv = field_name === "car_fmv" ? data : editLoanExpectedData.car_fmv;
        let dp_value = field_name === "dp_value" ? data : editLoanExpectedData.dp_value;
        if(car_fmv && dp_value){
            let down_payment = (car_fmv * dp_value)/100;
            let edit_data = {...editLoanExpectedData};
            edit_data.dp_amount = down_payment
            if(field_name === "car_fmv") edit_data.car_fmv = car_fmv;
            if(field_name === "dp_value") edit_data.dp_value = dp_value;
            setEditLoanExpectedData(edit_data)
        }
    }

    const isSubmitDisabled = useMemo(() => {
        return !allowSubmit;
    }, [allowSubmit]);

    const calculateLoan = async(type,toggleType = null,btnclick=0)=>{       
        let input_type = '', api_called = '';
        let getQuoteInput = {
            make_year: editLoanExpectedData?.yearofmanufacturing,
            tenure_in_month: editLoanExpectedData?.tenure,
            out_of_town_charge: editLoanExpectedData?.out_of_town_charges,
            car_fmv: editLoanExpectedData?.car_fmv, 
            vehicle_type: editLoanExpectedData?.vehicle_type,
            other_vehicle_type: editLoanExpectedData?.other_vehicle_type || "",           
            api_called_by: "web"
        }
        
        if(type === "calculate"){
            input_type = 'getQuoteInput';
            api_called = 'quotes';
            getQuoteInput = { ...getQuoteInput,
                // orcr_region_id: editLoanExpectedData?.orcr_region_id || 0,
                make_id: editLoanExpectedData?.brand,
                model_id: editLoanExpectedData?.model,
                variant_id: editLoanExpectedData?.variant,
                make_name: Number(editLoanExpectedData?.brand)===0 ? editLoanExpectedData?.make_name : allOptions?.["brand"]?.find(({ value }) => value === editLoanExpectedData?.["brand"])?.label || "",
                model_name: Number(editLoanExpectedData?.model)===0 ? editLoanExpectedData?.model_name : allOptions["model"].find(({ value }) => value === editLoanExpectedData?.["model"])?.label || "",
                variant_name: Number(editLoanExpectedData?.variant)===0 ? editLoanExpectedData?.variant_name : allOptions["variant"].find(({ value }) => value === editLoanExpectedData?.["variant"])?.label || "",
                dp_type: +editLoanExpectedData?.dp_type === 1 ? "per": "fixed",
                financier_id:Number(currentFinancer),
                lead_id:Number(lead_id)
            } 
            getQuoteInput= btnclick===1 ? { ...getQuoteInput, financier_ids : leadDetail.financier.map((fn)=>fn.financier_id)} : getQuoteInput;
            getQuoteInput = +editLoanExpectedData?.dp_type === 1 ? { ...getQuoteInput, 
                dp_value : 30, dp_amount:0 } :
            { ...getQuoteInput, dp_amount : editLoanExpectedData?.dp_amount, dp_value:0 } 
        }else if(type === "recalculate"){
            input_type = 'calculateLoanInput';
            api_called = 'calculate_loan';
            getQuoteInput = {
                ...getQuoteInput,
                financier_id: Number(currentFinancer) || 0,
                dp_type: requiredDpType?.required_dp_type === 1 ? "per": "fixed"
            }
            getQuoteInput = requiredDpType?.required_dp_type === 1 ? { ...getQuoteInput, 
                dp_value : requiredDpType?.required_dp_value, dp_amount:0 } :
            { ...getQuoteInput, dp_amount : requiredDpType?.required_dp_amount, dp_value:0 } 
        }

        let quote_arr=[];
        Object.keys(getQuoteInput).forEach((key) => {
            if(typeof getQuoteInput[key] === "string")
                quote_arr.push(`${key}: "${getQuoteInput[key]}"`)
            else if(key==="financier_ids")
                quote_arr.push(`${key}: "${getQuoteInput[key]}"`)
            else
                quote_arr.push(`${key}: ${getQuoteInput[key]}`)
        })
        //need to make orcr_region_id dynamic
        const query = gql`{${api_called}(${input_type}: {${quote_arr.join(', ')}}){
              make_year
              tenure_in_month
              dp_type
              dp_value
              dp_amount
              out_of_town_charge
              car_fmv
              vehicle_type
              other_vehicle_type
              quotes{
                financier_id
                interest_rate
                loan_amount
                dp_amount
                chattel_percent
                chattel_fee
                processing_fee
                dealer_incentive
                dealer_incentive_percent
                carmudi_top_up_percent
                installment_amount
                tax
                total_dealer_incentive_net_of_tax
                dealer_incentive_from_financier
                total_payment_to_dealer
                dealer_incentive_tax_percentage
                total_dealer_incentive
                dealer_incentive_from_carmudi
                total_payment_by_customer
                total_payment_from_financier
                dealer_incentive_from_financier_net_of_tax
                chattel_fee_info {
                    chattel_fee
                    chattel_percent
                    total_payment_by_customer
                    interest_rate
                    installment_amount
                    processing_fee
                    dealer_incentive_percent
                    dealer_incentive
                    dealer_incentive_from_financier
                    total_dealer_incentive
                    total_dealer_incentive_net_of_tax
                    total_payment_from_financier
                    total_payment_to_dealer
                    dealer_incentive_from_financier_net_of_tax
                    carmudi_top_up_percent
                    dealer_incentive_from_carmudi
                  }
              }
            }
          } `

        try {
            await executeGraphQLQuery(query, client).then(async(res) =>{
                let summary_details = type === "calculate" ? res && res.data && res.data.quotes : 
                type === "recalculate" ? res && res.data && res.data.calculate_loan : {};
                let summaryDetailsData = {
                    is_active: true,
                    dp_type: summary_details?.dp_type || "",
                    min_dp: summary_details?.dp_value || 0,
                    tenure: summary_details?.tenure_in_month || 0,
                    outoftowncharge: summary_details?.out_of_town_charge || 0,
                    car_fmv: summary_details?.car_fmv || 0,
                    make_year: summary_details?.make_year || 0,
                    vehicle_usage: editLoanExpectedData?.vehicle_usage || 0,
                    vehicle_type: summary_details?.vehicle_type || 0,
                    other_vehicle_type: summary_details?.other_vehicle_type || ""
                };

                //quote data
                let quote_data = type === "calculate" ? 
                res && res.data && res.data.quotes && res.data.quotes.quotes.filter(obj => obj.financier_id === Number(currentFinancer)) : res?.data?.calculate_loan?.quotes?.filter(obj => obj.financier_id === Number(currentFinancer)) || [];
                setAllOptions({...allOptions,chattel_percent: Array.from(new Set(quote_data?.[0]?.chattel_fee_info?.map(data => data.chattel_percent))).map(rate => ({ label: rate, value: rate })) || [], interest_rate: Array.from(new Set(quote_data?.[0]?.chattel_fee_info?.filter(quote => quote.chattel_percent === editLoanExpectedData?.chattel_percent)?.map(data => data.interest_rate))).map(rate => ({ label: rate, value: rate })) || []})
                if(type === 'calculate' && !res.data?.quotes?.quotes?.length) { 
                    toast.error("Quote Data does not exist");
                    setdisabledApproved(true);
                    return false;
                }
                else if(type === 'recalculate' && !res.data?.calculate_loan?.quotes?.length) { 
                    toast.error("Quote Data does not exist");
                    setdisabledApproved(true);
                    return false;
                } 
                let message="";
                if(btnclick===1){
                    leadDetail.financier.forEach((fn)=>{
                        if(!res.data.quotes.quotes.filter((qd)=> Number(fn.financier_id)===Number(qd.financier_id))?.length){
                            if(!message) message+=`Quote not exists for ${fn?.financier?.financierlang?.financier_name}`
                            else message+= `and ${fn?.financier?.financierlang?.financier_name}`
                        }
                    })
                    if(message) {
                        toast.error(message);
                        setdisabledApproved(true);
                        return false;
                    }
                }
                setdisabledApproved(false);
                let chattel_fee_info = quote_data[0]?.chattel_fee_info?.find(data => data.chattel_percent === editLoanExpectedData?.chattel_percent && data.interest_rate === editLoanExpectedData?.interest_rate); 
                if(quote_data && quote_data.length){
                    summaryDetailsData = {
                        ...summaryDetailsData,
                        "downpayment": quote_data[0]?.dp_amount || 0,
                        "loan_amount": quote_data[0]?.loan_amount || 0,
                        "interest_rate": chattel_fee_info?.interest_rate ? chattel_fee_info?.interest_rate : quote_data[0]?.interest_rate || 0,
                        "installment_amount": chattel_fee_info?.installment_amount ? chattel_fee_info?.installment_amount : quote_data[0]?.installment_amount || 0,
                        "dealer_incentive": chattel_fee_info?.dealer_incentive ? chattel_fee_info?.dealer_incentive : quote_data[0]?.dealer_incentive || 0,
                        "chattel_fee": chattel_fee_info?.chattel_fee >=0 ? chattel_fee_info?.chattel_fee : quote_data[0]?.chattel_fee || 0,
                        "dealer_incentive_percent": chattel_fee_info?.dealer_incentive_percent ? chattel_fee_info?.dealer_incentive_percent : quote_data[0]?.dealer_incentive_percent || 0,
                        "chattel_percent": chattel_fee_info?.chattel_percent ? chattel_fee_info?.chattel_percent : quote_data[0]?.chattel_percent || 0,
                        "carmudi_top_up_percent": chattel_fee_info?.carmudi_top_up_percent ? chattel_fee_info?.carmudi_top_up_percent : quote_data[0]?.carmudi_top_up_percent || 0,
                        "processing_fee": chattel_fee_info?.processing_fee >=0 ? chattel_fee_info?.processing_fee : quote_data[0]?.processing_fee || 0,
                        "tax": quote_data[0]?.tax || 0,
                        "totalPaymentByCustomer": chattel_fee_info?.total_payment_by_customer ? chattel_fee_info?.total_payment_by_customer : quote_data[0]?.total_payment_by_customer || 0,
                        "dealerIncentiveFromFinancer": chattel_fee_info?.dealer_incentive_from_financier ? chattel_fee_info?.dealer_incentive_from_financier : quote_data[0]?.dealer_incentive_from_financier || 0,
                        "dealerIncentiveFromCarmudi": chattel_fee_info?.dealer_incentive_from_carmudi ? chattel_fee_info?.dealer_incentive_from_carmudi : quote_data[0]?.dealer_incentive_from_carmudi || 0,
                        "totalDealerIncentive": chattel_fee_info?.total_dealer_incentive ? chattel_fee_info?.total_dealer_incentive : quote_data[0]?.total_dealer_incentive || 0,
                        "dealerIncentiveTaxPercent": quote_data[0]?.dealer_incentive_tax_percentage || 0,
                        "totalDealerIncentiveNetTax": chattel_fee_info?.total_dealer_incentive_net_of_tax ? chattel_fee_info?.total_dealer_incentive_net_of_tax : quote_data[0]?.total_dealer_incentive_net_of_tax || 0,
                        "totalPaymentfromFinancer": chattel_fee_info?.total_payment_from_financier ? chattel_fee_info?.total_payment_from_financier : quote_data[0]?.total_payment_from_financier || 0,
                        "totalPaymentToDealer": chattel_fee_info?.total_payment_to_dealer ? chattel_fee_info?.total_payment_to_dealer : quote_data[0]?.total_payment_to_dealer || 0,
                        "dealerIncentiveFromFinancerNetofTax" : chattel_fee_info?.dealer_incentive_from_financier_net_of_tax ? chattel_fee_info?.dealer_incentive_from_financier_net_of_tax : quote_data[0]?.dealer_incentive_from_financier_net_of_tax || 0,
                        financier_id:Number(currentFinancer)
                    }
                }

                setSummaryDetails([summaryDetailsData]);
                setquoteData(quote_data)
                // summaryDetailsData = await handleDetailChange(summaryDetailsData);
                if(type === "calculate" && toggleType !== 'EDITVIEW'){
                    
                    setEditView(true);
                    let quotedatainfo=[summaryDetailsData];
                    res && res.data && res.data.quotes && res.data.quotes.quotes?.filter((sf)=>Number(sf.financier_id)!==Number(currentFinancer)).forEach((obj)=>{
                        let quotedatadetails=leadDetail?.quote_data.filter((qd)=>Number(qd.financier_id)===Number(obj.financier_id))
                         chattel_fee_info = obj?.chattel_fee_info?.find(data => data.chattel_percent === quotedatadetails?.[0]?.chattel_percent); 
    
                        summaryDetailsData = {
                            ...summaryDetailsData,
                            outoftowncharge:quotedatadetails?.[0]?.out_of_town_charge || 0 ,
                            dp_type:quotedatadetails?.[0]?.dp_type,
                            dp_value:quotedatadetails?.[0]?.dp_value,
                            min_dp:quotedatadetails?.[0]?.dp_value,
                            financier_id:Number(obj.financier_id),
                            "downpayment": obj?.dp_amount || 0,
                            "loan_amount": obj?.loan_amount || 0,
                            "interest_rate": chattel_fee_info?.interest_rate ? chattel_fee_info?.interest_rate : obj?.interest_rate || 0,
                            "installment_amount": chattel_fee_info?.installment_amount ? chattel_fee_info?.installment_amount : obj?.installment_amount || 0,
                            "dealer_incentive": chattel_fee_info?.dealer_incentive ? chattel_fee_info?.dealer_incentive : obj?.dealer_incentive || 0,
                            "chattel_fee": chattel_fee_info?.chattel_fee >=0 ? chattel_fee_info?.chattel_fee : obj?.chattel_fee || 0,
                            "dealer_incentive_percent": chattel_fee_info?.dealer_incentive_percent ? chattel_fee_info?.dealer_incentive_percent : obj?.dealer_incentive_percent || 0,
                            "chattel_percent": chattel_fee_info?.chattel_percent ? chattel_fee_info?.chattel_percent : obj?.chattel_percent || 0,
                            "carmudi_top_up_percent": chattel_fee_info?.carmudi_top_up_percent ? chattel_fee_info?.carmudi_top_up_percent : obj?.carmudi_top_up_percent || 0,
                            "processing_fee": chattel_fee_info?.processing_fee >=0 ? chattel_fee_info?.processing_fee : obj?.processing_fee || 0,
                            "tax": obj?.tax || 0,
                            "totalPaymentByCustomer": chattel_fee_info?.total_payment_by_customer ? chattel_fee_info?.total_payment_by_customer : obj?.total_payment_by_customer || 0,
                            "dealerIncentiveFromFinancer": chattel_fee_info?.dealer_incentive_from_financier ? chattel_fee_info?.dealer_incentive_from_financier : obj?.dealer_incentive_from_financier || 0,
                            "dealerIncentiveFromCarmudi": chattel_fee_info?.dealer_incentive_from_carmudi ? chattel_fee_info?.dealer_incentive_from_carmudi : obj?.dealer_incentive_from_carmudi || 0,
                            "totalDealerIncentive": chattel_fee_info?.total_dealer_incentive ? chattel_fee_info?.total_dealer_incentive : obj?.total_dealer_incentive || 0,
                            "dealerIncentiveTaxPercent": obj?.dealer_incentive_tax_percentage || 0,
                            "totalDealerIncentiveNetTax": chattel_fee_info?.total_dealer_incentive_net_of_tax ? chattel_fee_info?.total_dealer_incentive_net_of_tax : obj?.total_dealer_incentive_net_of_tax || 0,
                            "totalPaymentfromFinancer": chattel_fee_info?.total_payment_from_financier ? chattel_fee_info?.total_payment_from_financier : obj?.total_payment_from_financier || 0,
                            "totalPaymentToDealer": chattel_fee_info?.total_payment_to_dealer ? chattel_fee_info?.total_payment_to_dealer : obj?.total_payment_to_dealer || 0,
                            "dealerIncentiveFromFinancerNetofTax" : chattel_fee_info?.dealer_incentive_from_financier_net_of_tax ? chattel_fee_info?.dealer_incentive_from_financier_net_of_tax : obj?.dealer_incentive_from_financier_net_of_tax || 0
                        }
                        quotedatainfo.push(summaryDetailsData);
                    })
                    setquoteData(quotedatainfo)
                }
                else if(type === "recalculate"){
                    
                    let summary_recalculate = [...summaryDetails];
                    summary_recalculate=summary_recalculate.map(obj =>({
                        ...obj,
                        is_active: false
                    }))
                    setApproveView(true);
                    setSummaryDetails([...summary_recalculate, summaryDetailsData]);
                } 
            })
            // dispatch(getLoanDetail(lead_id, client))
            // toast.success("Success");
        } catch (error) {
            toast.error("Failed");
            console.error(error);
        }
    }

    const handleSubmit = async() => {
        let loanExpected = {};
        if(summaryDetails.length){
            let quoteDataarr=[];
            summaryDetails?.filter(detail => detail.financier_id == currentFinancer)?.forEach((quote_detail, index) => {
                if(quote_detail.is_active === true){
                    loanExpected = {
                        "lead_id": Number(lead_id),
                        "financier_id": Number(quote_detail.financier_id),
                        "orcr_region_id": quote_detail.orcr_region_id || null,
                        "dp_amount": quote_detail.downpayment || 0,
                        "loan_amount": quote_detail.loan_amount || 0,
                        "interest_rate": quote_detail.interest_rate || 0,
                        "installment_amount": quote_detail.installment_amount || 0,
                        "tenure_in_month": quote_detail.tenure,
                        "dealer_incentive": quote_detail.dealer_incentive || 1,
                        "chattel_fee": quote_detail.chattel_fee || 0,
                        "dealer_incentive_percent": quote_detail.dealer_incentive_percent || 0,
                        "chattel_percent": quote_detail.chattel_percent || 0,
                        "carmudi_top_up_percent": quote_detail.carmudi_top_up_percent || 0,
                        "processing_fee": quote_detail.processing_fee || 0,
                        "out_of_town_charge": quote_detail.outoftowncharge || 0,
                        "car_fmv": quote_detail.car_fmv || 0,
                        "dp_type": quote_detail.dp_type || "",
                        "dp_value": quote_detail.min_dp || 0,
                        "tax": quote_detail.tax || 0,
                        "vehicle_usage": quote_detail.vehicle_usage || 1,
                        "vehicle_type": quote_detail.vehicle_type || 1,
                        "other_vehicle_type": quote_detail.other_vehicle_type || "",
                        "total_payment_by_customer": quote_detail.totalPaymentByCustomer || 0,
                        "dealer_incentive_from_financier": quote_detail.dealerIncentiveFromFinancer || 0,
                        "dealer_incentive_from_carmudi": quote_detail.dealerIncentiveFromCarmudi || 0,
                        "total_dealer_incentive": quote_detail.totalDealerIncentive || 0,
                        "dealer_incentive_tax_percentage": quote_detail.dealerIncentiveTaxPercent || 0,
                        "total_dealer_incentive_net_of_tax": quote_detail.totalDealerIncentiveNetTax || 0,
                        "total_payment_from_financier": quote_detail.totalPaymentfromFinancer || 0,
                        "total_payment_to_dealer": quote_detail.totalPaymentToDealer || 0,
                        "dealer_incentive_from_financier_net_of_tax" : quote_detail.dealerIncentiveFromFinancerNetofTax || 0,
                    }
                }
            })
            loanExpected = {...loanExpected, 
                "lead_details" : {
                "lead_id": Number(lead_id),
                "make_id": editLoanExpectedData.brand,
                "model_id": editLoanExpectedData.model,
                "variant_id": editLoanExpectedData.variant,
                "make_year": editLoanExpectedData.yearofmanufacturing,
                "make_name": editLoanExpectedData?.brand===0 ? editLoanExpectedData?.make_name : allOptions['brand'].find(({ value }) => value === editLoanExpectedData?.['brand'])?.label || "",
                "model_name": editLoanExpectedData?.model===0 ? editLoanExpectedData?.model_name : allOptions['model'].find(({ value }) => value === editLoanExpectedData?.['model'])?.label || "",
                "variant_name": editLoanExpectedData.variant===0 ? editLoanExpectedData?.variant_name : allOptions['variant'].find(({ value }) => value === editLoanExpectedData?.['variant'])?.label || ""
            }
            }
            quoteDataarr.push(loanExpected);
            // quoteDatainfo.filter((qd)=>Number(currentFinancer) === Number(qd.financier_id)).forEach((quote_detail,index)=>{
            //     loanExpected = {
            //         ...loanExpected,
            //         "lead_id": Number(lead_id),
            //         "financier_id": Number(quote_detail?.financier_id),
            //         "orcr_region_id": quote_detail.orcr_region_id || null,
            //         "dp_amount": quote_detail.downpayment || 0,
            //         "dp_value": quote_detail.dp_value || 0,
            //         "loan_amount": quote_detail.loan_amount || 0,
            //         "interest_rate": quote_detail.interest_rate || 0,
            //         "installment_amount": quote_detail.installment_amount || 0,
            //         "tenure_in_month": quote_detail.tenure,
            //         "dealer_incentive": quote_detail.dealer_incentive || 1,
            //         "chattel_fee": quote_detail.chattel_fee || 0,
            //         "dealer_incentive_percent": quote_detail.dealer_incentive_percent || 0,
            //         "chattel_percent": quote_detail.chattel_percent || 0,
            //         "carmudi_top_up_percent": quote_detail.carmudi_top_up_percent || 0,
            //         "processing_fee": quote_detail.processing_fee || 0,
            //         "out_of_town_charge": quote_detail.outoftowncharge || 0,
            //         //"car_fmv": quote_detail.car_fmv || 0,
            //         "dp_type": quote_detail.dp_type || "",
            //        // "dp_value": quote_detail.min_dp || 0,
            //         "tax": quote_detail.tax || 0,
            //         "vehicle_type": quote_detail.vehicle_type || 1,
            //         "other_vehicle_type": quote_detail.other_vehicle_type || "",
            //         "total_payment_by_customer": quote_detail.totalPaymentByCustomer || 0,
            //         "dealer_incentive_from_financier": quote_detail.dealerIncentiveFromFinancer || 0,
            //         "dealer_incentive_from_carmudi": quote_detail.dealerIncentiveFromCarmudi || 0,
            //         "total_dealer_incentive": quote_detail.totalDealerIncentive || 0,
            //         "dealer_incentive_tax_percentage": quote_detail.dealerIncentiveTaxPercent || 0,
            //         "total_dealer_incentive_net_of_tax": quote_detail.totalDealerIncentiveNetTax || 0,
            //         "total_payment_from_financier": quote_detail.totalPaymentfromFinancer || 0,
            //         "total_payment_to_dealer": quote_detail.totalPaymentToDealer || 0,
            //         "dealer_incentive_from_financier_net_of_tax" : quote_detail.dealerIncentiveFromFinancerNetofTax || 0,
            //     }
            //     quoteDataarr.push(loanExpected);
            // })

            let variables = {
                loanExpected: quoteDataarr
            };
            let mutation = gql`
                mutation saveLoanExpectedConfig($loanExpected: [LeadQuoteInput!]!) {
                    saveLoanExpectedConfig(loanExpected: $loanExpected) {
                        success
                    }
                }
            `;
    
            try {
                await executeGraphQLMutation(mutation, variables, client);
                dispatch(getLoanDetail(lead_id, client))
                toast.success("Success");
               // window.location.reload();
                // setLoading(false);
                
                setDisplayBlock("SUMMARY");
                setEditView(false);
                setApproveView(false);
                let quote=leadDetail?.quote_data?.filter((quote)=>quote.financier_id===Number(currentFinancer));
                await get_master_data(quote?.[0]?.vehicle_type)
                await getLeadDetailInput(currentFinancer)

            } catch (error) {
                // setLoading(false);
                toast.error(error.toString().replace('ApolloError:',''));
                console.error(error);
            }
        }else{
            toast.error("Min DP not selected!");
        }
    }

    let loanExpectedEditView = useMemo(() => {
        if (showChattelDD) {
            return LoanExpectedEditView.filter(v => !["chattel_percent", "interest_rate"].includes(v.id));
        }
        if(+editLoanExpectedData?.vehicle_type === 6){

            LoanExpectedEditView.forEach((fileds,key)=>{
                if(fileds.id==="brand" || fileds.id==="model" || fileds.id==="variant")
                LoanExpectedEditView[key].type="text";
            })
        }
        return LoanExpectedEditView;
      }, [allOptions, showChattelDD, leadDetail?.quote_data]);

    addValidationRule('isNegativeValue', function(values, value) {    
        if(value < 0) {
            return "Negative values are not allowed";
        }
        return true;
    });

    addValidationRule('isGreaterThanFMVValue', function(values, value) {    
        if(value < 0) {
            return "Negative values are not allowed";
        }
        else if (Number(value) >= Number(values.car_fmv)) {
            return "Amount must be less than FMV";
        }
        return true;
    });

    addValidationRule('isNegativeValueDpAmount', function(values, value) {    
        if(value < 0) {
            return "Negative values are not allowed";
        }
        else if (Number(value) >= Number(editLoanExpectedData.car_fmv)) {
            return "Amount must be less than FMV";
        }
        return true;
    });

    return (
        <div className=" laon-expect-outer">
            <div className="loan-expect-left-panle">

                <Tab.Container id="left-tabs-example" activeKey={activeTab}
                  onSelect={(tab, e) => onTabChange(tab, e)}
                   defaultActiveKey="finance-0">
                
                    <Nav variant="pills" className="flex-column nav nav-pills loan-expected-inner-tab">
                      <div className="tab-list">
                        { leadDetail && leadDetail.financier && leadDetail.financier.length ? leadDetail.financier.map((fn,key)=>(
                              <Nav.Item>
                                <Nav.Link eventKey={"finance-"+key} financeId={fn?.financier_id}>{fn?.financier?.financierlang?.financier_name}</Nav.Link>
                              </Nav.Item>
                        )) : null}

                        
                      </div>
                        
                    </Nav>
                    {/* <Tab.Content>
                        <Tab.Pane key="finance1" eventKey="finance1">
                            Finance 1
                        </Tab.Pane>
                        
                        <Tab.Pane key="finance2" eventKey="finance2">
                            Finance 2
                        </Tab.Pane>
                    </Tab.Content> */}

                </Tab.Container>

                <Formsy
                    className="lead-form-filed"
                    autoComplete="off"
                    onValid={() => setAllowSubmit(true)}
                    onInvalid={() => setAllowSubmit(false)}
                    onValidSubmit={(e) => {calculateLoan('calculate','',1);toggleswitch('SUMMARY', 'calculate_loan')}}
                >
                {/* default step start  */}
                {displayBlock === 'SUMMARY' && loanQuote?.length ?
                    <div className="loan-form">
                        <div className="heading-top">
                            <span>Carmudi Calculator</span>
                            <div className="action-icn">
                                <ul>
                                    <li style={{"display": !EditView && !editButtonCondition && !props.markAsFreezed ? "block":"none"}} onClick={() => toggleswitch('EDITVIEW', 'edit_btn')}>

                                        <i className="ic-createmode_editedit"></i>

                                    </li>
                                    {/* <li>

                                        <i className="ic-save_alt"></i>

                                    </li>
                                    <li onClick={showModalFinancerEmail}>

                                        <i className="ic-share"></i>

                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    {
                        summaryDetails.map((detail, index) =>(
                            <div className="loan-form-filed-scroll">
                            <div className="loan-filed-sec">
                                <div className="heading-dp-txt">
                                    {
                                        index === 0 ?
                                        <span>Summary Of Loan - {detail?.min_dp ? 'Min DP '+detail?.min_dp+`%` : 'Down Payment '+ generalService.numberFormatWithDots(detail?.downpayment || "")}</span>
                                    : <span>Required DP - {detail?.min_dp ? 'Min DP '+detail?.min_dp+`%` : 'Down Payment '+generalService.numberFormatWithDots(detail?.downpayment || "")}</span>
                                    }
                                    <div className="custom-control custom-checkbox check-box-small" style={{"display": ApproveView ? "block":"none"}}>
                                        <input
                                            id="summary_radio"
                                            type="radio"
                                            onChange={e => handleButtonChange(index)}
                                            name={"summary_radio"}
                                            // value={"pending"}
                                            // disabled={(form_submit_status == 1) ? true : ''}
                                            className="custom-control-input"
                                            checked={detail.is_active === true ? true : false}
                                            aria-label="summary radio"
                                        />{" "}
                                        <label className="custom-control-label"></label>
                                    </div>
                                </div>
                                <div className="form-group-bx">
                                    <h3>Basic Details</h3>
                                    <div className="row">
                                    { LoanExpectedBasicDetails.map((field) => (
                                        ["number-format"].includes(field.type) ? (
                                    <fieldset class="form-filed col-md-6 ">
                                        <FormsyInputField
                                            id={field.id}
                                            name={field.name}
                                            type={field.type}
                                            value={(Math.round((detail && detail[field.name]) * 1000) / 1000)  || 0}
                                            placeholder=" "
                                            {...(field.type === "number-format") ? {
                                                thousandsGroupStyle:"thousand",
                                                thousandSeparator: ',',
                                                decimalSeparator: '.'
                                            }: {}}
                                            label={field.label}
                                            // onChange={(e) => handleInputChange(field.id, e, field.type, "split_payment", index)}
                                            format={field.ApplyNumberformat ? field.format : ""}
                                            validations={field.validations}
                                            validationError={field.validationError}
                                            // required={field.required}
                                            disabled={field.readOnly}
                                            readonly={field.readOnly}
                                        />
                                    </fieldset>
                                        ) : null
                                    ))}
                                    </div>
                                </div>

                                <div className="form-group-bx">
                                    <h3>For Customer</h3>
                                    <div className="row">
                                    { LoanExpectedCustomerDetails.map((field) => (
                                        ["number-format"].includes(field.type) ? (
                                    <fieldset class="form-filed col-md-6 ">
                                        <FormsyInputField
                                            col='col-md-12'
                                            id={field.id}
                                            name={field.name}
                                            type={field.type}
                                            value={(Math.round((detail && detail[field.name]) * 1000) / 1000) || 0}
                                            placeholder=" "
                                            {...(field.type === "number-format") ? {
                                                thousandsGroupStyle:"thousand",
                                                thousandSeparator: ',',
                                                decimalSeparator: '.'
                                            }: {}}
                                            label={field.label}
                                            // onChange={(e) => handleInputChange(field.id, e, field.type, "split_payment", index)}
                                            format={field.ApplyNumberformat ? field.format : ""}
                                            validations={field.validations}
                                            validationError={field.validationError}
                                            // required={field.required}
                                            disabled={field.readOnly}
                                            readonly={field.readOnly}
                                        />
                                    </fieldset>
                                        ) : null
                                    ))}
                                    </div>
                                </div>

                                <div className="form-group-bx">
                                    <h3>For Dealer</h3>
                                    <div className="row">
                                    { LoanExpectedDealerDetails.map((field) => (
                                        ["number-format"].includes(field.type) ? (
                                    <fieldset class="form-filed col-md-6 ">
                                        <FormsyInputField
                                            col='col-md-12'
                                            id={field.id}
                                            name={field.name}
                                            type={field.type}
                                            value={(Math.round((detail && detail[field.name]) * 1000) / 1000) || 0}
                                            placeholder=" "
                                            {...(field.type === "number-format") ? {
                                                thousandsGroupStyle:"thousand",
                                                thousandSeparator: ',',
                                                decimalSeparator: '.'
                                            }: {}}
                                                label={field.label}
                                            // onChange={(e) => handleInputChange(field.id, e, field.type, "split_payment", index)}
                                            format={field.ApplyNumberformat ? field.format : ""}
                                            validations={field.validations}
                                            validationError={field.validationError}
                                            // required={field.required}
                                            disabled={field.readOnly}
                                            readonly={field.readOnly}
                                        />
                                    </fieldset>
                                        ) : null
                                    ))}
                                    </div>
                                </div>

                                <div className="form-group-bx">
                                    <h3>For Carmudi</h3>
                                    <div className="row">
                                    { LoanExpectedCarmudiDetails.map((field) => (
                                        ["number-format"].includes(field.type) ? (
                                    <fieldset class="form-filed col-md-6 ">
                                        <FormsyInputField
                                            col='col-md-12'
                                            id={field.id}
                                            name={field.name}
                                            type={field.type}
                                            value={(Math.round((detail && detail[field.name]) * 1000) / 1000) || 0}
                                            placeholder=" "
                                        {...(field.type === "number-format") ? {
                                            thousandsGroupStyle:"thousand",
                                            thousandSeparator: ',',
                                            decimalSeparator: '.'
                                          }: {}}
                                            label={field.label}
                                            // onChange={(e) => handleInputChange(field.id, e, field.type, "split_payment", index)}
                                            format={field.ApplyNumberformat ? field.format : ""}
                                            validations={field.validations}
                                            validationError={field.validationError}
                                            // required={field.required}
                                            disabled={field.readOnly}
                                            readonly={field.readOnly}
                                        />
                                    </fieldset>
                                        ) : null
                                    ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))
                    }
                            <div className="loan-filed-sec" style={{"display": EditView && !ApproveView ? "block":"none"}}>
                                <div className="required-dp-txt">
                                    <span>Add Required DP%s</span>
                                </div>
                                <div className="row">
                                    <fieldset class="single-select col-md-6">
                                        <FormsySelect
                                        name="required_dp_type"
                                        id="required_dp_type"
                                        inputProps={{
                                            options: DPType || [],
                                            placeholder: "DP Type",
                                            className: "react-select",
                                            classNamePrefix: "react-select",
                                            value: DPType.filter(({ value }) => value === requiredDpType?.["required_dp_type"])
                                        }}
                                        value={requiredDpType && requiredDpType["required_dp_type"]}
                                        onChange={(data) => handleSelectChange(data, "required_dp_type", "summary")}
                                        />
                                    </fieldset> 
                                    {
                                        requiredDpType && requiredDpType["required_dp_type"] === 1 ?
                                        <fieldset class="single-select col-md-6">
                                            <FormsySelect
                                                name="required_dp_value"
                                                id="required_dp_value"
                                                inputProps={{
                                                    options: allOptions?.['dp_value'] || [],
                                                    placeholder: "DP Value",
                                                    className: "react-select",
                                                    classNamePrefix: "react-select",
                                                    value: allOptions && allOptions['dp_value'] && allOptions['dp_value'].filter(({ value }) => Number(value) === Number(requiredDpType?.["required_dp_value"]))
                                                }}
                                                required={true}
                                                value={requiredDpType && requiredDpType?.["required_dp_value"]}
                                                onChange={(data) => handleSelectChange(data, "required_dp_value", "summary")}
                                            />                                   
                                        </fieldset> :
                                        requiredDpType && requiredDpType["required_dp_type"] === 2 ?
                                        <fieldset class="form-filed col-md-6">
                                            <FormsyInputField
                                                    id="required_dp_amount"
                                                    name="required_dp_amount"
                                                    type="number-format"
                                                    label="DP Amount"
                                                    value={(Math.round((requiredDpType && requiredDpType?.["required_dp_amount"]) * 1000) / 1000)  || 0}
                                                    placeholder="DP Amount"
                                                    thousandsGroupStyle="thousand"
                                                    thousandSeparator=','
                                                    decimalSeparator='.'
                                                    onChange={(e) => handleInputChange("required_dp_amount", e, "number-format", "summary")}
                                                    validations={'isNegativeValueDpAmount'}
                                                    validationError={""}
                                                    required={true}
                                            />
                                        </fieldset> : null
                                    }                                                  
                                    
                                    <div className="col-md-6 p-l-0">
                                    { (accessCondition || !requiredDpType["required_dp_type"]
                                            || ((requiredDpType["required_dp_type"] === 1 && !requiredDpType["required_dp_value"]) 
                                        || (requiredDpType["required_dp_type"] === 2 && 
                                        (!requiredDpType["required_dp_amount"] || requiredDpType["required_dp_amount"] < 0 || requiredDpType["required_dp_amount"] > editLoanExpectedData.car_fmv)))) ? <span className={requiredDpType["required_dp_type"] ?"span-disabled m-lg-t" :"span-disabled"}>Calculate</span> :
                                        <button type="button" className="btn-primary btn-submit m-lg-t" 
                                        disabled={ accessCondition || !requiredDpType["required_dp_type"]
                                            || ((requiredDpType["required_dp_type"] === 1 && !requiredDpType["required_dp_value"]) 
                                        || (requiredDpType["required_dp_type"] === 2 && 
                                        (!requiredDpType["required_dp_amount"] || requiredDpType["required_dp_amount"] < 0 || requiredDpType["required_dp_amount"] > editLoanExpectedData.car_fmv)))}
                                        onClick={e => {calculateLoan("recalculate");toggleswitch('SUMMARY', 'calculate_loan')}}>Calculate</button>
                                    }
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{"display": EditView ? "block":"none"}}>
                            <div className="col-md-6 p-l-0">
                            { (disabledApproved || accessCondition || !summaryDetails.filter(obj => obj.is_active === true)
                                             || ((requiredDpType["required_dp_type"] === 1 && !requiredDpType["required_dp_value"]) 
                                        || (requiredDpType["required_dp_type"] === 2 && !requiredDpType["required_dp_amount"]))) ? <span className="span-disabled">Approve</span> :
                                    <button type="button" className="btn-primary btn-submit" 
                                        disabled={ disabledApproved || accessCondition || !summaryDetails.filter(obj => obj.is_active === true)
                                             || ((Number(requiredDpType["required_dp_type"]) === 1 && !requiredDpType["required_dp_value"]) 
                                        || (Number(requiredDpType["required_dp_type"]) === 2 && !requiredDpType["required_dp_amount"]))}
                                    onClick={handleSubmit}>Approve</button>
                                        }
                            </div>
                            <div className="col-md-6 p-l-0">
                                    <button type="button" className="btn-primary btn-submit" 
                                        // disabled={!requiredDpType || ((requiredDpType["required_dp_type"] == 1 && !requiredDpType["required_dp_value"]) 
                                        // || (requiredDpType["required_dp_type"] == 2 && !requiredDpType["required_dp_amount"]))}
                                    onClick={e => window.location.reload()}>Cancel</button>
                            </div>
                            </div>                            
                    </div>
                    : !loanQuote?.length && !displayQuote ? 
                    <div className="generate-quote">
                        <p className="no-quote">No Quote Available</p>
                        <button type="button" className="btn-line btn-submit gen-quote" disabled = {props?.markAsFreezed} onClick={() => setDisplayQuote(true)}> <i className="ic-add"></i> Generate Quote</button>
                    </div> : null}
                    {
                        displayQuote && !loanQuote?.length &&
                        <div className="loan-form">
                            <LoanQuoteTabs allOptions={allOptions} setAllOptions={setAllOptions} getMake={getMake} getModel={getModel} getVersion={getVersion} addValidationRule={addValidationRule} lead_id={lead_id}></LoanQuoteTabs>
                        </div>
                    }
                {/* end  */}


                {/* loan edit step  */}
                {displayBlock === 'EDITVIEW' && loanQuote?.length ?
                    <div className="loan-form">
                        <div className="heading-top">
                            <span>Carmudi Calculator</span>
                            <div className="action-icn">
                                <ul>
                                    {/* <li>
                                        <i className="ic-save_alt"></i>
                                    </li> */}
                                    {/* <li>

                                        <i className="ic-share"></i>

                                    </li> */}
                                </ul>
                            </div>
                        </div>

                        <div className="loan-form-filed-scroll">
                            <div className="loan-filed-sec">
                                <div className="heading-dp-txt">
                                    <span>Summary Of Loan - {summaryDetails?.[0]?.min_dp ? 'Min DP '+summaryDetails?.[0]?.min_dp+`%` : 'Down Payment '+ generalService.numberFormatWithDots(summaryDetails?.[0]?.downpayment || "")}</span>
                                </div>
                                <div className="form-group-bx">
                                    <h3>Basic Details </h3>
                                    <div className="row">
                                    {loanExpectedEditView.map((field) => (
                                             ((field?.dependentOn && field?.dependentValue.includes(editLoanExpectedData[field?.dependentOn])) || !field?.dependentOn) && ["text", "number-format"].includes(field.type) ? (
                                        <fieldset class="form-filed col-md-6"
                                        style={{display : ((`${field.name}` === "other_vehicle_type" && (!editLoanExpectedData?.["vehicle_type"] || editLoanExpectedData?.["vehicle_type"] !== 6))) ? "none" : "block"}}>
                                            <FormsyInputField
                                                id={field.id}
                                                name={field.name}
                                                type={field.type}
                                                value={(field.type === "number-format") ? (Math.round((editLoanExpectedData && editLoanExpectedData[field.name]) * 1000) / 1000) : (editLoanExpectedData && editLoanExpectedData[field.name])}
                                                placeholder=" "
                                                {...(field.type === "number-format") ? {
                                                    thousandsGroupStyle:"thousand",
                                                    thousandSeparator: ',',
                                                    decimalSeparator: '.'
                                                }: {}}
                                                label={field.label}
                                                onChange={(e) => handleInputChange(field.id, e, field.type, "edit_view")}
                                                format={field.ApplyNumberformat ? field.format : ""}
                                                validations={field.type === "number-format" && field.name === 'car_fmv' ? 'isNegativeValue' :
                                                field.type === "number-format" && field.name !== 'car_fmv' ? "isGreaterThanFMVValue" : field.validations}
                                                validationError={field.validationError}
                                                required={editLoanExpectedData?.["dp_type"] === 2 && `${field.name}` === "dp_amount" ? true : field.required}
                                                disabled={editLoanExpectedData?.["dp_type"] === 1 && `${field.name}` === "dp_amount" ? true : field.readOnly}
                                            />
                                        </fieldset>
                                            ) : 
                                            field.type === "dropdown" ? (
                                                <fieldset class="single-select col-md-6" 
                                                style={{display : ((`${field.name}` === "model" && !editLoanExpectedData?.["brand"]) || 
                                                (`${field.name}` === "variant" && (!editLoanExpectedData?.["brand"] || !editLoanExpectedData?.["model"]))) ? "block" : "block"}}>
                                                <FormsySelect
                                                    name={field.name}
                                                    id={field.id}
                                                    inputProps={{
                                                        options: `${field.name}` === "dp_type" ? DPType : ['vehicle_type'].includes(field?.name) ? vehicleTypeOptions : 
                                                        allOptions?.[field.optionsKey] || [],
                                                        placeholder: field.label,
                                                        className: "react-select",
                                                        classNamePrefix: "react-select",
                                                        value: `${field.name}` === "dp_type" ? DPType?.filter(({ value }) => value === editLoanExpectedData?.[field.name]) : ['vehicle_type'].includes(field?.name) ? vehicleTypeOptions?.filter(({ value }) => Number(value) === editLoanExpectedData?.[field?.name]) : 
                                                         allOptions && allOptions[field.optionsKey] && allOptions[field.optionsKey].filter(({ value }) => value == editLoanExpectedData?.[field.name]),
                                                        isDisabled: editLoanExpectedData?.["dp_type"] === 2 && `${field.name}` === "dp_value" ? true : field.readOnly
                                                    }}
                                                    required={editLoanExpectedData?.["dp_type"] === 1 && `${field.name}` === "dp_value" ? true : field.required}
                                                    value={editLoanExpectedData && editLoanExpectedData[field.name]}
                                                    onChange={(data) => ["brand", "model","vehicle_usage","vehicle_type"].includes(field.name) ? handleMakeChange(field.name, data) : handleSelectChange(data, field.name, "edit_view")}
                                                />
                                                </fieldset>
                                            ) : null
                                        ))}
                                    </div>
                                </div>
                                <div class="btn-save-remarks">
                                {(isSubmitDisabled || accessCondition) ? <span className="span-disabled">Calculate</span> : 
                                    <button type="submit" class="btn-primary" disabled={isSubmitDisabled || accessCondition}>Calculate</button>
                                }
                                    <button type="button" class="btn-line" onClick={() => toggleswitch('SUMMARY', 'cancel')}>Cancel</button>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    : null}
                {/* end */}

            </Formsy>
            </div>



            <div className="loan-expect-right-panel">
                <LoanExpectedRightPanel lead_id={lead_id} remarksHistory={remarksHistory} editButtonCondition={editButtonCondition} markAsFreezed={props.markAsFreezed} editLoanExpectedData={editLoanExpectedData} allOptions={allOptions} accessCondition={accessCondition}/>
            </div>

            <div className="view-timeline-popup image-timeline-popup financer-email-popup share-popup">
                <Modal show={FinancerEmail} handleClose={hideModalFinancerEmail} >
                    <div className="modal-header m-xl-b">
                        <h2>Share Loan Quote</h2>
                        {/* <span className="sub-heading">Sent on 22 Sep 2021, 4:30 PM</span> */}
                    </div>
                    <div className="modal-body">
                        <div className="financer-email-data">
                            <ul>
                                <li>
                                    <label>Package Leasing</label>
                                    <span>Customer to Customer</span>
                                </li>
                                <li>
                                    <label>MRP of the Car</label>
                                    <span>100.000.000</span>
                                </li>
                                <li>
                                    <label>Disburse to Customer</label>
                                    <span>110.000.000</span>
                                </li>
                                <li>
                                    <label>Installment Amount</label>
                                    <span>20.000</span>
                                </li>
                                <li>
                                    <label>Tenure</label>
                                    <span>4</span>
                                </li>
                                <li>
                                    <label>Number of Installments</label>
                                    <span>48</span>
                                </li>
                                <li>
                                    <label>Flat Interest Rate/ Year</label>
                                    <span>7%</span>
                                </li>
                                <li>
                                    <label>Flat Interest Rate/ Month</label>
                                    <span>8%</span>
                                </li>
                            </ul>
                        </div>

                        <div className="email-attech-doc-list">
                            <h3>Share With</h3>
                            <ul>
                                <li className="active">
                                    BM
                                </li>
                                <li>
                                    Customer
                                </li>
                                <li>Dealer</li>
                                <li>Agent/ BRO</li>
                            </ul>
                        </div>
                        <div className="btn-save-remarks m-xl-t">
                            <button type="button" className="btn-primary" >Share</button>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>

    )
}

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};
export default LoanExpected;