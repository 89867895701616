import React, { useState } from 'react';

const Toggle = ({ checked, onChange, disabled }) => {
  return (
    <label className="toggle-switch-common">
      <input
        type="checkbox"
        checked={checked || null}
        onChange={onChange}
        disabled={disabled}
      />
      <span className="slider"></span>
    </label>
  );
};

export default Toggle;
