import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import { useSelector, useDispatch } from "react-redux";
import { useOutletContext, useParams } from "react-router-dom";
import FormFields from "./formFields/paymentVerification.json";
import FormsyInputField from '../elements/FormsyInputField';
import FormsyDatePicker from '../elements/FormsyDatePicker';
import SplitPaymentForm from './SplitPaymentForm';
import { gql, useApolloClient } from '@apollo/client';
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import { getLoanDetail } from '../../store/action/allAction';
import Loader from '../elements/Loader';
import { toast } from "react-toastify";
import dateFormat from "dateformat";
import LeadRemarkHistory from '../lead-list/LeadRemarkHistory';
import Modal from '../elements/Modal';
import { addValidationRule } from 'formsy-react';
import {
  CHECK_ASSIGN_USER, 
  FINANCIER_DECIMAL_LIMIT, 
  FINANCIER_ID, 
  IS_USER_ACCESS_PAGE, 
  STATUS_CODES, 
  SUB_STATUS_CODES,
  CONFIRMATION_TXT, 
  getKeysData,
  TRANCHE_STATUS,
  PAYMENT_TYPE,
} from '../../../src/config/constants';
import crypto from '../../config/crypto'
import DisputedPaymentView from './payment-verification/DisputedPaymentView';

const FORM_FIELDS = FormFields["payment_verification_details"];
const SPLIT_PAYMENT_FIELDS = FormFields["split_payment_details"];

const PaymentVerificationForm = (props) => {
  const { payViaDisputed, setPayViaDisputed, disputeData, setDisputeData, screenToShow, setScreenToShow, paymentVerificationDetails, setPaymentVerificationDetails, splitPaymentDetails, setSplitPaymentDetails, common_fields,
    savePaymentVerificationOne,
    isPaidViaRecoverySelected
   } = props;
    const params = useParams();
    let lead_id = crypto.decode(params.lead_id);
    const client = useApolloClient();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [disabledbutton,setDisabledbutton]=useState(false);
    const userInfo = useSelector((state) => state.user.user_information);
    const user_id = userInfo ? JSON.parse(userInfo).id || 0 : 0;
    const [allowSubmit, setAllowSubmit] = useState(false);
    const { leadDetail } = useSelector(({ lead }) => {
        return {
            leadDetail: lead.leadDetail
        }
    });
    const getContext = useOutletContext();
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [showremarkform,setShowremarkform]=useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [confirmationChecked, setConfirmationChecked] = useState(false);
    const [isRegenrationCase, setIsRegenrationCase] = useState(false);
    let accessCondition= !IS_USER_ACCESS_PAGE("payment_verification", "payment_verification")?.is_edit_access;
    if(!accessCondition){
      accessCondition=CHECK_ASSIGN_USER(leadDetail?.assign_to);
    }

    const { remarksHistory } = useSelector(({lead}) => ({
      remarksHistory:lead.remarksHistory || []
    }));

    let mainDealerFlag = leadDetail?.lead_computation_details?.loan_amount_pass_through === 'yes' ? false : true;

    const isADPCase = useMemo(() => {
      return leadDetail?.lead_computation_details?.adp_type === "Yes"; 
    }, [leadDetail?.lead_computation_details?.adp_type]);

    const financierId = useMemo(() => {
      return leadDetail?.financier?.filter(data => data.is_proceed === 'yes')?.[0]?.financier_id
    }, [leadDetail?.financier]);

    const sortSplitPaymentType = (splitPaymentDetails) => {
      let split_payment_details = [...splitPaymentDetails];
      let filterSplitPayment = split_payment_details.filter(obj => obj.status === "1");
      split_payment_details.filter(obj => obj.status === "2").map(obj => {
        filterSplitPayment = [...filterSplitPayment, obj];
        return filterSplitPayment;
      })  
      setSplitPaymentDetails(filterSplitPayment);  
    }
    const handleDateChange = (date, field_name) => {
        if (date && field_name) {
            setPaymentVerificationDetails(currentValue => ({
                ...currentValue,
                [field_name]: dateFormat(date, 'yyyy-mm-dd')
            }))
        }
    }

    const handleInputChange = (target_id, event, type, payment_type, index) => {
        let value = type === "number-format" ? event.floatValue : type === 'dropdown' ? event.value : event.target.value;
        let updatedSplitPayment = [...splitPaymentDetails];
        if (target_id) {
            if(payment_type === "mandatory_fields"){
                setPaymentVerificationDetails(currentValue => ({
                    ...currentValue,
                    [target_id]: value
                }))
            }else if(payment_type === "split_payment" && index != null){
                if(target_id === 'bank_account') {
                  leadDetail?.dealerData?.[0]?.bank_details?.forEach(dealer => {
                    if(dealer.account_number === value) {
                      updatedSplitPayment[index] = {
                        ...updatedSplitPayment[index],
                        [target_id]: value,
                        bank_name: dealer.bank_name,
                        branch_name: dealer.branch_name,
                        account_holder_name: dealer.account_holder_name,
                        account_number: dealer.account_number
                      }
                    }
                  })
                  if(isADPCase && index === 0){
                    updatedSplitPayment[1] = {
                      ...updatedSplitPayment[1],
                      [target_id]: value,
                      bank_name: updatedSplitPayment[0].bank_name,
                      branch_name: updatedSplitPayment[0].branch_name,
                      account_holder_name: updatedSplitPayment[0].account_holder_name,
                      account_number: updatedSplitPayment[0].account_number
                    };
                  }
                  if(value === 'custom') {
                    updatedSplitPayment[index] = {
                      ...updatedSplitPayment[index],
                      [target_id]: value,
                      bank_name: '',
                      branch_name: '',
                      account_holder_name: '',
                      account_number: ''
                    }
                  }
                }
                else if(target_id === "amount")
                  updatedSplitPayment[index] = {...updatedSplitPayment[index], [target_id]: event?.target?.value ? parseFloat(event?.target?.value.replace(/,/g, '')) : 0}
                else
                  updatedSplitPayment[index] = {...updatedSplitPayment[index], [target_id]: value}
                updateMainDealerPayment(updatedSplitPayment);
            }
        }
    }

    const updateMainDealerPayment = (updatedSplitPayment) => {
      let split_index = mainDealerFlag ? (isADPCase ? 2 : 1) : 0;
      if(mainDealerFlag) {
        updatedSplitPayment[0] = {...updatedSplitPayment[0], "amount": isADPCase ? parseFloat(leadDetail?.lead_computation_details?.advance_loan_value_to_be_paid) : parseFloat(leadDetail?.lead_computation_details?.loan_amount_pass_through === null ? leadDetail?.lead_computation_details?.total_dealer_payment : leadDetail?.lead_computation_details?.final_loan_value)}
        if (isADPCase) {
          updatedSplitPayment[1] = {...updatedSplitPayment[1], "amount": parseFloat(leadDetail?.lead_computation_details?.remaining_loan_value_to_be_paid)}
        }
        if(leadDetail?.lead_computation_details?.dealer_incentive_net_of_tax > 0)
          updatedSplitPayment[split_index] = {
            ...updatedSplitPayment[split_index],
            amount:
              (isADPCase ? 
              parseFloat(leadDetail?.lead_computation_details?.dealer_incentive_net_of_tax_to_be_paid) : parseFloat(
                leadDetail?.lead_computation_details
                  ?.loan_amount_pass_through === null
                  ? leadDetail?.lead_computation_details?.total_dealer_payment
                  : leadDetail?.lead_computation_details
                      ?.dealer_incentive_net_of_tax
              )) -
              (updatedSplitPayment.length === (isADPCase ? 4 : 3) &&
              updatedSplitPayment[split_index + 1]["amount"]
                ? parseFloat(updatedSplitPayment[split_index + 1]["amount"])
                : 0),
          };
        setSplitPaymentDetails(updatedSplitPayment);
      }
      else {
        updatedSplitPayment[0] = {
          ...updatedSplitPayment[0],
          amount:
            parseFloat(
              leadDetail?.lead_computation_details?.loan_amount_pass_through ===
                null
                ? leadDetail?.lead_computation_details?.total_dealer_payment
                : leadDetail?.lead_computation_details
                    ?.dealer_incentive_net_of_tax
            ) -
            ((updatedSplitPayment.length >= 2 &&
            updatedSplitPayment[split_index + 1]["amount"]
              ? parseFloat(updatedSplitPayment[split_index+ 1]["amount"])
              : 0)),
        };
        setSplitPaymentDetails(updatedSplitPayment);
      }
    }

    const addSplitPaymentChange = () => {
        let obj = {
          ...common_fields,
          ...getKeysData({}, ["bank_name", "branch_name", "account_holder_name", "account_number"]),
            "payment_type": PAYMENT_TYPE.SplitDealer2,
            "amount": 0,
        }
        setSplitPaymentDetails([...splitPaymentDetails, obj]);
        sortSplitPaymentType([...splitPaymentDetails, obj]);
    }

    const handleEditChange = (index) => {
        if(index !== 0){
            let updatedSplitPayment = [...splitPaymentDetails];
            updatedSplitPayment[index] = {...updatedSplitPayment[index], "is_edit": !updatedSplitPayment[index]["is_edit"]}
            setSplitPaymentDetails(updatedSplitPayment);
        }
    }

    const handleDeleteChange = async(index) => {
        if(index !== 0){
            let updatedSplitPayment = [...splitPaymentDetails];
            if(updatedSplitPayment[index]['is_save'] === 1)
              updatedSplitPayment[index] = {...updatedSplitPayment[index], "status": "2"}
            else
              updatedSplitPayment = updatedSplitPayment.filter((obj, i) => i !== index);
            await updateMainDealerPayment(updatedSplitPayment);
            // setSplitPaymentDetails(updatedSplitPayment);
            sortSplitPaymentType(updatedSplitPayment);
        }
    }

    const handleSubmit = async () => {
      let splitPayment = [], temp_obj = {};
      setLoading(true);
      setDisabledbutton(true)
      splitPaymentDetails.map((obj, index) => {
        temp_obj = {
          "id": obj.id,
          "lead_id": Number(lead_id),
          "payment_type": obj.payment_type,
          "bank_name": obj.bank_name || "",
          "branch_name": obj.branch_name || "",
          "account_holder_name": obj.account_holder_name || "",
          "account_number": obj.account_number || "",
          "amount": Number(obj.amount),
          "status": obj.status,
          "created_by":user_id, 
          "updated_by":user_id,
          "is_tranche_marked": obj.is_tranche_marked,
          is_custom_bank_selected: obj?.bank_account === "custom" ? true : false
        }
        splitPayment.push(temp_obj);
        return obj;
      })
      let finalResponse = {
        "lead_id": Number(lead_id),
        "adp_type": isADPCase,
        "mandatory_fields": paymentVerificationDetails,
        "split_payment": splitPayment,
        "created_by":user_id
      }
      
      let leadPushtoPb = leadDetail?.title_payment?.filter(title=> ["requested", "paid", "on_hold"].includes(title.tranche_status))?.length;
      let serviceCondition = ((leadDetail?.oti_sub_status_id === SUB_STATUS_CODES.COMPUTATION_VERIFIED) && !leadPushtoPb) || [SUB_STATUS_CODES.COMPUTATION_REGENERATED].includes(leadDetail?.oti_sub_status_id);
      let noTrancheExist = leadDetail?.is_pushed_to_pb==="1" && (!paymentVerificationDetails["loan_value"] && !paymentVerificationDetails["total_dealer_incentive_of_tax"]);
      let variables = serviceCondition || noTrancheExist ? {
        paymentVerificationInput: finalResponse
      } : {
        sendTrancheInput : {
          "lead_id": Number(lead_id),
          "adp_type": isADPCase,
          "split_payment": splitPayment,
          "created_by":user_id
        }
      };

      let mutation = serviceCondition || noTrancheExist ? gql`mutation savePaymentVerification($paymentVerificationInput: PaymentVerificationInput!) {
        payment_verification_save(paymentVerificationInput: $paymentVerificationInput) {
          success
        }
      }`: gql`
      mutation send_tranche($sendTrancheInput:PaymentVerificationInput!) {
        send_tranche(sendTrancheInput: $sendTrancheInput) {
          success
        }
      }`;

      try {
        let main_dealer = (mainDealerFlag && splitPayment?.find(split => split.payment_type === 'main_dealer')) || {}
        let stored_main_dealer = leadDetail?.split_payment?.find(data => data.payment_type === 'main_dealer') || {}
        let isMainDealerChecked = mainDealerFlag ? (main_dealer?.is_tranche_marked || ['paid', 'requested'].includes(stored_main_dealer?.tranche_status)) : true;
        if(isMainDealerChecked) {
          await executeGraphQLMutation(mutation, variables, client);
          dispatch(getLoanDetail(lead_id, client));
          toast.success("Success");
          splitPayment = splitPaymentDetails?.map(data => ({
            ...data,
           is_tranche_marked: false
          }))
          setSplitPaymentDetails([...splitPayment]);
        } else {
          setDisabledbutton(false)
          toast.error("Please select Main dealer tranche first")
        }
      } catch (error) {
          toast.error(error.toString().replace('ApolloError:',''));
          setDisabledbutton(false)
          console.error(error);
      } finally {
        hideConfirmation();
        setLoading(false);
        setConfirmationModal(false);
        setConfirmationChecked(false);
      }
    }

    // All the conditions can be combined here for button enable/disable
    let isSubmitDisabled = useMemo(() => {
      if (leadDetail) {
        let currentStatus = leadDetail?.status_id;
        let currentSubStatus = leadDetail?.oti_sub_status_id;
        let currentDocStatus = leadDetail?.doc_status;
        let sub_status_ids = leadDetail?.lead_status_history?.map(history => history?.sub_status_id);
        const { PAYMENT_VERIFICATION_ONE, PAYMENT_RECOVERY, PAYMENT_VERIFICATION_TWO } = SUB_STATUS_CODES;
        return [PAYMENT_VERIFICATION_ONE, PAYMENT_RECOVERY, PAYMENT_VERIFICATION_TWO].some(sub_status_id => sub_status_ids.includes(sub_status_id)) || !allowSubmit || (([STATUS_CODES.LOST].includes(currentStatus)) || ([SUB_STATUS_CODES.CUSTOMER_DETAIL_FILLED, SUB_STATUS_CODES.COMPUTATION_RESET].includes(currentSubStatus)) || currentDocStatus==='0');
      }
      return true;
    }, [allowSubmit,leadDetail, leadDetail?.lead_status_history]);

    let splitDataObj = splitPaymentDetails?.find(data=> data?.is_tranche_marked)
    let isCheckboxMarked = false;
    let statusMarkedDealerIncentivePaid = leadDetail?.lead_status_history?.filter(data => [9, 41].includes(data.sub_status_id))?.length ?  true : false;
    if (paymentVerificationDetails["loan_value"] === 0 && paymentVerificationDetails["total_dealer_incentive_of_tax"] === 0 && [41].includes(leadDetail?.lead_status_history?.[leadDetail?.lead_status_history?.length-1]?.sub_status_id)){
      isCheckboxMarked =  false;
    }
    else if (statusMarkedDealerIncentivePaid && (paymentVerificationDetails["loan_value"] === 0 && paymentVerificationDetails["total_dealer_incentive_of_tax"] === 0)){
      isCheckboxMarked =  true;
    } else if(leadDetail?.is_pushed_to_pb==="1" && (paymentVerificationDetails["loan_value"] > 0 || paymentVerificationDetails["total_dealer_incentive_of_tax"] > 0)) {
      // getContext.markAsFreezed = false;
      isCheckboxMarked = !(splitDataObj?.is_tranche_marked);
    }

  const showModalViewTimeLine = () => {
      setViewTimeLine(true);
      setShowremarkform(true);
      document.body.classList.add("overflow-hidden");
    };
  
    const hideModalViewTimeLine = () => {
      setViewTimeLine(false);
      document.body.classList.remove("overflow-hidden");
    };

    const showConfirmation = () => {
      if (payViaDisputed) {
        savePaymentVerificationOne();
        return;
      }
      setConfirmationModal(true);
      document.body.classList.add("overflow-hidden");
    }

    const hideConfirmation = () => {
      setConfirmationModal(false);
      setConfirmationChecked(false);
      document.body.classList.remove("overflow-hidden");
    }

    const handleConfirmationCheckbox = (event) => {
      if (event.target.checked) setConfirmationChecked(true);
      else setConfirmationChecked(false);
    }

    addValidationRule('isZeroValue', function(values, value) {  
      return value < 0 ? "Amount should be greater than zero" : true;
    });

    addValidationRule('isNegativeValue', function(values, value) {   
      return value < 0 ? "Negative Values are not allowed" : true;
    });

    useEffect(() => {
      const isSplitTranchReqOrPaid = leadDetail?.split_payment?.find((payment) => {
          if (["split_dealer", "split_dealer2"].includes(payment?.payment_type) && ["requested", "paid"].includes(payment?.tranche_status)) {
              return true;
          }
      })
      if ([SUB_STATUS_CODES.COMPUTATION_VERIFIED, SUB_STATUS_CODES.COMPUTATION_REGENERATED, SUB_STATUS_CODES.PAID_TO_DEALER].includes(leadDetail?.oti_sub_status_id) && (!leadDetail?.split_payment?.length || !isSplitTranchReqOrPaid)) {
          setIsRegenrationCase(true);
      }
    }, [leadDetail?.split_payment])
  
  const isAddSplitTranceEnable = useMemo(() => {
    const { split1, split2 } = splitPaymentDetails?.reduce((result, obj) => {
      if (obj.status === "1" && obj?.payment_type === PAYMENT_TYPE.SplitDealer && !obj?.tranche_status) result.split1 = true;
      else if (obj.status === "1" && obj?.payment_type === PAYMENT_TYPE.SplitDealer2) result.split2 = true;
      return result;
    }, { split1: false, split2: false }) || { split1: false, split2: false };
    return !isPaidViaRecoverySelected && split1 && !split2 && leadDetail?.lead_computation_details?.dealer_incentive_net_of_tax > 0;
  }, [isPaidViaRecoverySelected, splitPaymentDetails, splitPaymentDetails?.length, leadDetail?.lead_computation_details?.dealer_incentive_net_of_tax]);
  
    const isTranchRequested = useMemo(() => {
      return leadDetail?.split_payment?.some((payment) => ["requested"].includes(payment?.tranche_status))
    }, [leadDetail?.split_payment])
    const sectionInfo = {section:"Payment verification" , sub_section:""}
  const isPayViaRecoveryInitiated = useMemo(() => {
    const { PAYMENT_VERIFICATION_ONE, PAYMENT_RECOVERY, PAYMENT_VERIFICATION_TWO } = SUB_STATUS_CODES;
    return leadDetail?.lead_status_history?.some(({ sub_status_id }) => [PAYMENT_VERIFICATION_ONE, PAYMENT_RECOVERY, PAYMENT_VERIFICATION_TWO].includes(sub_status_id));
  }, [leadDetail?.lead_status_history]);
    return (
      <div className="image-form-outer">
        {loading ? <Loader /> : null}
        <div>
          <div className="lead-detail-from-outer">
            <Formsy
              className="lead-form-filed"
              autoComplete="off"
              onValid={() => setAllowSubmit(true)}
              onInvalid={() => setAllowSubmit(false)}
              onValidSubmit={showConfirmation}
            >
              <div className="row">
                {FORM_FIELDS.map((field) => (
                    ["text", "pattern-format", "number-format"].includes(field.type) ? (
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={paymentVerificationDetails && paymentVerificationDetails[field.name]}
                        placeholder=" "
                        {...(field.type === "number-format") ? {
                          thousandsGroupStyle:"thousand",
                          thousandSeparator: ',',
                          decimalSeparator: '.'
                        }: {}}
                        label={field.label}
                        onChange={(e) => handleInputChange(field.id, e, field.type, "mandatory_fields", null)}
                        format={field.ApplyNumberformat ? field.format : ""}
                        validations={field.type === "number-format" ? field.name === "total_dealer_incentive_of_tax" ? 'isNegativeValue' : 'isZeroValue' : field.validations}
                        validationError={field.validationError}
                        required={field.required}
                        disabled={["tin_number", "total_payment_to_dealer", "loan_value", "total_dealer_incentive_of_tax"].includes(field.name) || disabledbutton ? true : false}
                        decimalScale={(Number(financierId) === FINANCIER_ID.JACCS_FINANCE && FINANCIER_DECIMAL_LIMIT.JACCS_FINANCE) || FINANCIER_DECIMAL_LIMIT.DEFAULT}
                    />
                  </fieldset>
                    ) : 
                    field.type === "date-picker" ? (
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyDatePicker
                          name={field.name}
                          selectedDate={paymentVerificationDetails && paymentVerificationDetails[field.name] && new Date(paymentVerificationDetails[field.name])}
                          placeholder={field.label}
                          maxDate={new Date()}
                          value={paymentVerificationDetails && paymentVerificationDetails[field.name]}
                          onDateSelect={(date) => handleDateChange(date, field.name)}
                          dateFormat="yyyy-mm-dd"
                          validations={field.validations}
                          validationError={field.validationError}
                          required={field.required}
                          hasError={(paymentVerificationDetails.hasOwnProperty(field.name) && !paymentVerificationDetails[field.name]) ? true : false}
                          disabled={["accreditation_date"].includes(field.name) ?  true:false}
                      />
                    </fieldset>
                    ) : null
                ))}
              </div>

              {splitPaymentDetails?.length && splitPaymentDetails?.map((trancheData, index) => (
                <SplitPaymentForm
                  trancheData={trancheData}
                  isPayViaRecoveryInitiated={isPayViaRecoveryInitiated}
                  handleInputChange={handleInputChange}
                  splitPaymentDetails={splitPaymentDetails}
                  setSplitPaymentDetails={setSplitPaymentDetails}
                  index={index}
                  splitPaymentFields={SPLIT_PAYMENT_FIELDS}
                  handleEditChange={handleEditChange}
                  handleDeleteChange={handleDeleteChange}
                  isSave={leadDetail?.split_payment?.length}
                  leadDetail={leadDetail}
                  disabledbutton={disabledbutton}
                  mainDealerFlag={mainDealerFlag}
                  isCheckboxMarked={isCheckboxMarked}
                  setDisabledbutton={setDisabledbutton}
                  isADPCase={isADPCase}
                  financierId={financierId}
                  isRegenrationCase={isRegenrationCase}
                /> 
              )) || <></>}

              {
              isAddSplitTranceEnable ?
                <span className='add-split-link' onClick={addSplitPaymentChange}>Add Split Payment</span>
                : <></>
              }
              {(disputeData?.length && <DisputedPaymentView disputeData={disputeData} payViaDisputed={payViaDisputed} setPayViaDisputed={setPayViaDisputed} isPaidViaRecoverySelected={isPaidViaRecoverySelected} isTranchRequested={isTranchRequested} />) || ""}
              
              <div className="btn-save-remarks">
                <br></br>
                {(isSubmitDisabled || disabledbutton ||accessCondition || isCheckboxMarked) ?  <span className="span-disabled">Send & Next</span> :
                <button type="submit" className="btn-primary" disabled={(isSubmitDisabled || disabledbutton || accessCondition || isCheckboxMarked) }>
                  Send & Next
                </button>
                }
                <button type="button" className="btn-line" onClick={showModalViewTimeLine}>Remarks
                  <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                </button>
              </div>
            </Formsy>
          </div>
        </div>
        <div className="view-timeline-popup">
          <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
            <div className="modal-header">
              <h2>Remark History</h2>
            </div>
            <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezed} accessCondition={accessCondition} sectionInfo={sectionInfo}/>
          </Modal>
          <Modal show={confirmationModal} handleClose={hideConfirmation} >
            <div className="modal-header">
                <h2>Confirmation</h2>
            </div>
            <div className="modal-body" >
              <div className="custom-control custom-checkbox">
                <input
                  id={"confirmation-checkbox"}
                  type="checkbox"
                  className={"custom-control-input"}
                  onChange={handleConfirmationCheckbox}
                  checked={confirmationChecked}
                  aria-label="confirmation model"
                />
                <label
                  data-label="form"
                  htmlFor="confirmation-checkbox"
                  className="custom-control-label"
                >
                  {CONFIRMATION_TXT} 
                </label>
              </div>
            </div>
            <div className="modal-footer" >
              <br></br>
              {loading ? <Loader /> : (
                !confirmationChecked ? <span className="span-disabled">Proceed to Payment</span> :
                <button className="btn-primary" onClick={handleSubmit} disabled={!confirmationChecked }>Proceed to Payment</button>
              )}
            </div>
          </Modal>
        </div>
      </div>
    );
}
export default PaymentVerificationForm;