import React, { useEffect, useMemo, useState } from 'react';
import Formsy, { addValidationRule } from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentGallery from '../DocumentGallery';
import FormFields from "../formFields/coBorrowerDetailsFormFields.json";
import FormsyInputField from '../../elements/FormsyInputField';
import FormsySelect from '../../elements/FormsySelect';
import LeadRemarkHistory from '../../lead-list/LeadRemarkHistory';
import Modal from '../../elements/Modal';
import { SAVE_COBORROWER_BANK_DETAILS } from '../../../services/coborrower';
import { executeGraphQLMutation } from '../../../common/executeGraphQLQuery';
import { toast } from 'react-toastify';
import { useApolloClient } from '@apollo/client';
import { getLoanDetail } from '../../../store/action/allAction';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE, SUB_STATUS_CODES} from '../../../config/constants';
import { useAiIcon } from '../../hooks/useAiIcon';
import AiIcon from '../../elements/ImageAI';
import { SAVE_VIBER_DATA } from '../../../services/leads.gql';

const FORM_FIELDS = FormFields["BANK_DETAILS"];
const ACCOUNT_TYPE_OPTIONS = [
  { id: "1", label: "Savings", value: "savings" },
  { id: "2", label: "Payroll", value: "payroll" },
]

addValidationRule("cb_matchAccNo", (values, value) => {
  if (value && values.cb_account_number) {
    return values.cb_account_number !== values.cb_re_account_number ? "Account No. does not match" : true
  }
  return true;
})

const BankDetails = (props) => {
    const [bankDetails, setBankDetails] = useState({});
    const [orgbankDetails, setOrgBankDetails] = useState({});
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [showremarkform,setShowremarkform]=useState(false);
    const { masterdata, lead, remarksHistory } = useSelector(({masterdata, lead}) => ({
      masterdata,
      lead,
      remarksHistory:lead.remarksHistory || []
    }));
    const context = useOutletContext();
    const documentCategoryList = context.docList || []
    const viberCoborrowerData = context?.viberData?.get_viber_column?.co_borrower || [];
    const { showAiIcon } = useAiIcon(bankDetails, viberCoborrowerData);

    const client = useApolloClient();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let { lead_id } = useParams();
    let accessCondition = !IS_USER_ACCESS_PAGE("loanDetail", "co-borrower-bank-details")?.is_edit_access;
    if(!accessCondition){
      accessCondition=CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
    }
    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    let user_id = (UserInfo && UserInfo.id) ? UserInfo.id : 0;

    useEffect(() => {
      if (lead && lead.leadDetail && lead.leadDetail.co_borrower && lead.leadDetail.co_borrower.bank_details) {
        let originalData = { ...lead.leadDetail.co_borrower.bank_details };
        let updatedData = { ...originalData };

        if (viberCoborrowerData) {
          Object.keys(viberCoborrowerData).forEach(key => {
            if (!updatedData[key] && viberCoborrowerData[key]) {
              updatedData[key] = viberCoborrowerData[key];
            }
          });
        }

        originalData["cb_re_account_number"] = originalData["cb_account_number"] || "";
        updatedData["cb_re_account_number"] = updatedData["cb_account_number"] || "";

        setOrgBankDetails(originalData);
        setBankDetails(updatedData);
      }
    }, [lead]);

    let ALL_OPTIONS = useMemo(() => {
      let options = masterdata ? {...masterdata.data} : {};
      options["account_type"] = ACCOUNT_TYPE_OPTIONS;
      return options;
    }, [masterdata]);

    const handleInputChange = event => {
        if (event.target.id) {
            setBankDetails(currentValue => ({
                ...currentValue,
                [event.target.id]: event.target.value
            }))
        }
    }

    const handlePatternFormatChange = (data, field_name) => {
        if (field_name && data.value !== undefined) {
            setBankDetails(currentValue => ({
                ...currentValue,
                [field_name]: data.value
            }))
        }
    }

    const handleSelectChange = (data, field_name) => {
      if (data && field_name) {
        setBankDetails(currentValue => ({
          ...currentValue,
          [field_name]: data.value
        }))
      }
    }

    const handleSubmit = () => {
      let lead_id = lead?.leadDetail?.id || "";
      let coborrower_id = lead?.leadDetail?.co_borrower?.co_borrower_id;
      let reqBody = {
        lead_id: lead_id,
        co_borrower_id: coborrower_id,
        cb_bank_id: Number(bankDetails.cb_bank_id) || null,
        cb_branch_name: bankDetails.cb_branch_name,
        cb_account_name: bankDetails.cb_account_name,
        cb_account_type: bankDetails.cb_account_type,
        cb_account_number: bankDetails.cb_account_number,
        updated_by: user_id || 0
      }
      Object.keys(reqBody).forEach(key => {
        if (reqBody[key] === "") reqBody[key] = null;
      });

      let viberReqBody = {
        lead_id: lead_id,
        parent_object_name: 'co_borrower',
        changed_object: {
          cb_bank_id: bankDetails.cb_bank_id?.toString() || null,
          cb_branch_name: bankDetails.cb_branch_name?.toString() || null,
          cb_account_name: bankDetails.cb_account_name?.toString() || null,
          cb_account_type: bankDetails.cb_account_type?.toString() || null,
          cb_account_number: bankDetails.cb_account_number?.toString() || null,
        }
      };

      let viberMutation = SAVE_VIBER_DATA;
      executeGraphQLMutation(viberMutation, viberReqBody, client)
        .then(resp => {
          console.log(resp);
        })
        .catch(err => console.log(err));

      let mutation = SAVE_COBORROWER_BANK_DETAILS, 
          variables = { cb_bankDetailsInput: reqBody };

      executeGraphQLMutation(mutation, variables, client).then(resp => {
        let response = resp?.data?.save_cb_bank_details || null;
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        dispatch(getLoanDetail(lead_id,client))
        navigateToNext();
      })
      .catch(err => {
        toast.error(err.message);
      })
    }

    const navigateToNext = () => {
      navigate(`/lead-detail/co-borrower-details/residential-information/${lead_id}`);
    }


    let BankDetailsForm = FORM_FIELDS;

    // All the conditions can be combined here for button enable/disable
    const isSubmitDisabled = useMemo(() => {
      return lead.leadDetail.lead_status_history.filter(v=>v.sub_status_id===6).length ? true : false;
    }, [lead.leadDetail]);

    const showModalViewTimeLine = () => {
      setViewTimeLine(true);
      setShowremarkform(true);
      document.body.classList.add("overflow-hidden");
    };
  
    const hideModalViewTimeLine = () => {
      setViewTimeLine(false);
      document.body.classList.remove("overflow-hidden");
    };

    const sectionInfo = {section : "Co-borrower details" , sub_section : "Bank details"}
    let { BASIC_DETAILS_FILLED, LOAN_QUOTE_SELECTED, CUSTOMER_DETAIL_FILLED, APPLICATION_CREATED} = SUB_STATUS_CODES
    let isSubStatus3Marked = [BASIC_DETAILS_FILLED, LOAN_QUOTE_SELECTED, CUSTOMER_DETAIL_FILLED, APPLICATION_CREATED].includes(lead.leadDetail?.sub_status_id) ? false : true;        
    return (
      <div className="image-form-outer">
        <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList}  />
        </div>
        <div className="form-tab-right-panel">
          <div className="lead-detail-from-outer">
            <div className="lead-form-heading">
              <span className="main-heading">Co-Borrower Details</span>
              <h2>Bank Details</h2>
            </div>
            <Formsy
              className="lead-form-filed"
              autoComplete="off"
              onValid={() => setAllowSubmit(true)}
              onInvalid={() => setAllowSubmit(false)}
              onValidSubmit={handleSubmit}
            >
              <div className="row">
                
                {BankDetailsForm.map((field) => (
                    ["text", "password","pattern-format", "number-format"].includes(field.type) ? (
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={(bankDetails && bankDetails[field.name] || '')}
                        placeholder=" "
                        thousandsGroupStyle="thousand"
                        thousandSeparator={','}
                        decimalSeparator={'.'}
                        label={field.label}
                        onChange={
                        ["pattern-format", "number-format"].includes(field.type) 
                        ? (data) => handlePatternFormatChange(data, field.name)
                        : handleInputChange
                        }
                        format={field.ApplyNumberformat ? field.format : ""}
                        validations={field.validations}
                        validationError={
                        bankDetails[field.name] ? field.validationError : ""
                        }
                        required={field.required}
                        readOnly={orgbankDetails?.[field.name] && isSubStatus3Marked ? true : false}
                        
                    />
                  {showAiIcon(field.name) ? <AiIcon /> : null}
                  </fieldset>
                    ) : 
                    
                    field.type === "dropdown" ? (
                    <fieldset class="single-select col-md-6">
                      <FormsySelect
                        name={field.name}
                        id={field.id}
                        inputProps={{
                            options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                            placeholder: field.label,
                            className: "react-select",
                            classNamePrefix: "react-select",
                            value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ value }) => value === (bankDetails[field.name]?.toString())),
                            isDisabled: field.readOnly || (orgbankDetails?.[field.name] && isSubStatus3Marked) ? true : false
                        }}
                        required={field.required}
                        value={bankDetails[field.name]}
                        onChange={(data) => handleSelectChange(data, field.name)}
                      />
                    {showAiIcon(field.name) ? <AiIcon /> : null}
                    </fieldset>
                    ) : null 
                ))}
                
              </div>

              <div className="btn-save-remarks">
              {(isSubmitDisabled || !allowSubmit || context.markAsFreezedNewFlow || accessCondition) ?
              <span className="span-disabled">Save & Next</span> : 
                <button aria-label="save next" type="submit" className="btn-primary" disabled={isSubmitDisabled || !allowSubmit || context.markAsFreezedNewFlow || accessCondition}>
                  Save & Next
                </button>
                }
                <button aria-label="remarks history" type="button" className="btn-line" onClick={showModalViewTimeLine}>Remarks
                  <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                </button>
              </div>
            </Formsy>
          </div>
        </div>
        <div className="view-timeline-popup">
          <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
            <div className="modal-header">
              <h2>Remark History</h2>
            </div>
            <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={context.markAsFreezedNewFlow} accessCondition={accessCondition} sectionInfo = {sectionInfo}/>
          </Modal>
        </div>
      </div>
    );
}
export default BankDetails;