import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentGallery from '../DocumentGallery';
import FormFields from "../formFields/customerDetailsFormFields.json";
import FormsyInputField from '../../elements/FormsyInputField';
import FormsySelect from '../../elements/FormsySelect';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import { CHECK_ASSIGN_USER, CUSTOMER_PRIMARY_DOC_ID, CUSTOMER_SECONDARY_DOC_ID, SUB_STATUS_CODES } from "./../../../config/constants";
import LeadRemarkHistory from '../../lead-list/LeadRemarkHistory';
import Modal from '../../elements/Modal';
import { SAVE_CUSTOMER_ADDITIONAL_DETAILS } from '../../../services/customer.gql';
import { executeGraphQLMutation } from '../../../common/executeGraphQLQuery';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { getLoanDetail } from '../../../store/action/allAction';
import { IS_USER_ACCESS_PAGE} from '../../../config/constants';
import FormsyDatePicker from '../../elements/FormsyDatePicker';
import dateFormat from 'dateformat';
import { useAiIcon } from '../../hooks/useAiIcon';
import AiIcon from '../../elements/ImageAI';
import { SAVE_VIBER_DATA } from '../../../services/leads.gql';

const FORM_FIELDS = FormFields["ADDITIONAL_DETAILS"];
let DEPENDENTS_OPTIONS = Array.from({ length: 11 }, (_, index) => ({
  id: index,
  label: `${index}`,
  value: index,
}));

let INSURANCE_OPTIONS = [{id:1,label:'Yes',value:'yes'},{id:2,label:'No',value:'no'}]


const AdditionalDetails = (props) => {
    const [additionalDetails, setAdditionalDetails] = useState({});
    const [orgadditionalDetails, setOrgAdditionalDetails] = useState({});
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [showremarkform,setShowremarkform]=useState(false);
    const { masterdata, lead, remarksHistory, leadFiles } = useSelector(({masterdata, lead}) => ({
      masterdata,
      lead,
      remarksHistory:lead.remarksHistory || [],
      leadFiles: lead && (lead.leadDetail && (lead.leadDetail.leaddocs || [])),
    }));   
    const context = useOutletContext();
    const documentCategoryList = context.docList || [] ;
    const viberPersonalData = context?.viberData?.get_viber_column?.personal_details || [];
    const { showAiIcon } = useAiIcon(additionalDetails, viberPersonalData);
    let navigate = useNavigate();
    let { lead_id } = useParams();

    const client = useApolloClient();
    const dispatch = useDispatch()
    let accessCondition = !IS_USER_ACCESS_PAGE("loanDetail", "customer-additional-details")?.is_edit_access;
    if(!accessCondition){
      accessCondition=CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
    }

    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    let user_id = (UserInfo && UserInfo.id) ? UserInfo.id : 0;

    useEffect(() => {
      if (lead && lead.leadDetail && lead.leadDetail.customer) {
        let originalData = {
          ...lead.leadDetail.customer,
          insurance_exists: lead.leadDetail.leadinsurance?.insurance_exists,
          insurer_name: lead.leadDetail.leadinsurance?.insurer_name,
          expiration_date: lead.leadDetail.leadinsurance?.expiration_date ? dateFormat(lead.leadDetail.leadinsurance?.expiration_date, "dd mmm, yyyy") : null,
          interested: lead.leadDetail.leadinsurance?.interested
        };
        let updatedData = { ...originalData };

        if (viberPersonalData) {
          Object.keys(viberPersonalData).forEach(key => {
            if (!updatedData[key] && viberPersonalData[key]) {
              if (key === "expiration_date") {
                updatedData[key] = dateFormat(viberPersonalData[key], "dd mmm, yyyy");
              } else {
                updatedData[key] = viberPersonalData[key];
              }
            }
          });
        }
        setOrgAdditionalDetails(originalData);
        setAdditionalDetails(updatedData);
      }
    }, [lead]);

    const restructureDocList = (docList,primary_doc_id=0) => {
      let docListOptions = [];
      docList.forEach(v => {
        if (v.doclang) {
          if( v?.doclang?.doc_id!==primary_doc_id) {
          docListOptions.push({
            id: v?.doclang?.doc_id || "",
            label: v?.doclang?.name || "",
            value: v?.doclang?.doc_id || "",
          })
          }
        }
      });
      return docListOptions;
    }


    let ALL_OPTIONS = useMemo(() => {
      let options = masterdata ? {...masterdata.data} : {};
      let allDocList = context && context.docList;
      if (allDocList && allDocList.length) {
        let primaryDocList = allDocList.find(v => v.id === CUSTOMER_PRIMARY_DOC_ID)?.child || [];
        let secondaryDocList = allDocList.find(v => v.id === CUSTOMER_SECONDARY_DOC_ID)?.child || [];
      
        primaryDocList = restructureDocList(primaryDocList,additionalDetails.secondary_doc_id);
        secondaryDocList = restructureDocList(secondaryDocList,additionalDetails.primary_doc_id);
        options["primary_docList"] = primaryDocList || [];
        options["secondary_docList"] = secondaryDocList || [];
      }
      options["dependents"] = DEPENDENTS_OPTIONS;
      options["insurance"] = INSURANCE_OPTIONS;
      return options;
    }, [context, masterdata,additionalDetails]);

  
    const handleInputChange = event => {
        if (event.target.id) {
            setAdditionalDetails(currentValue => ({
                ...currentValue,
                [event.target.id]: event.target.value
            }))
        }
    }

    const handlePatternFormatChange = (data, field_name) => {
        if (field_name && data.value !== undefined) {
            setAdditionalDetails(currentValue => ({
                ...currentValue,
                [field_name]: data.value
            }))
        }
    }

    const handleSelectChange = (data, field_name) => {
      // console.log(ALL_OPTIONS.primary_docList,"aaaaaaaaaa",field_name);
      if (data && field_name) {
        setAdditionalDetails(currentValue => ({
          ...currentValue,
          [field_name]: data.value
        }))
      }
    }

    const handleDateChange = (date, field_name) => {
      if (date && field_name) {
          setAdditionalDetails(currentValue => ({
              ...currentValue,
              [field_name]: date
          }))
      }
  }

    const handleSubmit = () => {
      let lead_id = lead?.leadDetail?.id || "";
      let reqBody = {
        lead_id: lead_id,
        primary_doc_id: additionalDetails.primary_doc_id || null,
        primary_card_no: additionalDetails.primary_card_no || null,
        secondary_doc_id: additionalDetails.secondary_doc_id || null,
        secondary_card_no: additionalDetails.secondary_card_no || null,
        qualification: additionalDetails.qualification || null,
        no_of_dependent: additionalDetails.no_of_dependent || null,
        father_name: additionalDetails.father_name || null,
        mother_name: additionalDetails.mother_name || null,
        facebook_url: additionalDetails.facebook_url || null,
        tin_number: additionalDetails.tin_number || null,
        updated_by: user_id || 0
      }
      let insuranceDetails = {
        insurance_exists: additionalDetails.insurance_exists || null,
        insurer_name: additionalDetails.insurer_name || null,
        expiration_date: additionalDetails.expiration_date ? dateFormat(additionalDetails.expiration_date,"yyyy-mm-dd") : null,
        interested: additionalDetails.interested || null,
      }
      if (additionalDetails.insurance_exists === "no") {
        insuranceDetails.insurer_name = '';
        insuranceDetails.expiration_date = null;
      }
      else if (additionalDetails.insurance_exists === "yes") {
        insuranceDetails.interested = 'no';
      }
      let mutation = SAVE_CUSTOMER_ADDITIONAL_DETAILS, 
          variables = { additionalDetailsInput: reqBody, insuranceDetailsInput: insuranceDetails };

      let viberReqBody = {
        lead_id: lead_id,
        parent_object_name: 'personal_details',
        changed_object: {
          primary_doc_id: additionalDetails.primary_doc_id?.toString() || null,
          primary_card_no: additionalDetails.primary_card_no?.toString() || null,
          secondary_doc_id: additionalDetails.secondary_doc_id?.toString() || null,
          secondary_card_no: additionalDetails.secondary_card_no?.toString() || null,
          qualification: additionalDetails.qualification?.toString() || null,
          no_of_dependent: additionalDetails.no_of_dependent?.toString() || null,
          father_name: additionalDetails.father_name?.toString() || null,
          mother_name: additionalDetails.mother_name?.toString() || null,
          facebook_url: additionalDetails.facebook_url?.toString() || null,
          tin_number: additionalDetails.tin_number?.toString() || null,
          insurance_exists: additionalDetails.insurance_exists?.toString() || null,
          insurer_name: additionalDetails.insurer_name?.toString() || null,
          expiration_date: additionalDetails.expiration_date ? dateFormat(additionalDetails.expiration_date, "yyyy-mm-dd") : null,
          interested: additionalDetails.interested?.toString() || null
        }
      };

      if (additionalDetails.insurance_exists === "no") {
        viberReqBody.changed_object.insurer_name = '';
        viberReqBody.changed_object.expiration_date = null;
      } else if (additionalDetails.insurance_exists === "yes") {
        viberReqBody.changed_object.interested = 'no';
      }

      let viberMutation = SAVE_VIBER_DATA;
      executeGraphQLMutation(viberMutation, viberReqBody, client)
        .then(resp => {
          console.log(resp);
        })
        .catch(err => console.log(err));

      executeGraphQLMutation(mutation, variables, client).then(resp => {
        let response = resp?.data?.save_customer_additional_details || null;
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        dispatch(getLoanDetail(lead_id,client))
        navigateToNext();
      })
      .catch(err => {
        toast.error(err.message);
      })
    }

    const navigateToNext = () => {
      navigate(`/lead-detail/customer-details/employment-details/${lead_id}`);
    }

    let AdditionalDetailsForm = FORM_FIELDS;

    AdditionalDetailsForm = useMemo(() => {
      if (additionalDetails) {
        if (additionalDetails.insurance_exists === "yes") {
          return FORM_FIELDS.filter(v => v.id !== "interested");
        } 
        else if (additionalDetails.insurance_exists === "no") {
          return FORM_FIELDS.filter(v => !['insurer_name','expiration_date'].includes(v.id));
        } 
        else if (additionalDetails.insurance_exists === null) {
          return FORM_FIELDS.filter(v => !['interested','insurer_name','expiration_date'].includes(v.id));
        } 
      }
      return FORM_FIELDS;
      
    }, [additionalDetails]);

    // All the conditions can be combined here for button enable/disable
    const isSubmitDisabled = useMemo(() => {
      return lead.leadDetail.lead_status_history.filter(v=>v.sub_status_id===6).length ? true : false;
    }, [lead.leadDetail]);

    const showModalViewTimeLine = () => {
      setViewTimeLine(true);
      setShowremarkform(true);
      document.body.classList.add("overflow-hidden");
    };
  
    const hideModalViewTimeLine = () => {
      setViewTimeLine(false);
      document.body.classList.remove("overflow-hidden");
    };


    const sectionInfo = {section : "Customer details" , sub_section : "Additional details"}
    
    let { BASIC_DETAILS_FILLED, LOAN_QUOTE_SELECTED, CUSTOMER_DETAIL_FILLED, APPLICATION_CREATED} = SUB_STATUS_CODES
    let isSubStatus3Marked = [BASIC_DETAILS_FILLED, LOAN_QUOTE_SELECTED, CUSTOMER_DETAIL_FILLED, APPLICATION_CREATED].includes(lead.leadDetail?.sub_status_id) ? false : true;
    return (
      <div className="image-form-outer">
        <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList}  />
        </div>
        <div className="form-tab-right-panel">
          <div className="lead-detail-from-outer">
            <div className="lead-form-heading">
              <span className="main-heading">Customer Details</span>
              <h2>Additional Details</h2>
            </div>
            <Formsy
              className="lead-form-filed"
              autoComplete="off"
              onValid={() => setAllowSubmit(true)}
              onInvalid={() => setAllowSubmit(false)}
              onValidSubmit={handleSubmit}
              aria-label="lead form field"
            >
              <div className="row">
                
                {AdditionalDetailsForm.map((field) => (
                    ["text", "pattern-format"].includes(field.type) ? (
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={(additionalDetails && additionalDetails[field.name] || '')}
                        placeholder=" "
                        label={field.label}
                        onChange={
                        field.type === "pattern-format" 
                        ? (data) => handlePatternFormatChange(data, field.name)
                        : handleInputChange
                        }
                        format={field.ApplyNumberformat ? field.format : ""}
                        validations={field.validations}
                        validationError={
                        additionalDetails[field.name] ? field.validationError : ""
                        }
                        required={field.required}
                        showAsterisk={field.showAsterisk}
                        readOnly={orgadditionalDetails?.[field.name] && isSubStatus3Marked}
                    />
                    {showAiIcon(field.name) ? <AiIcon /> : null}
                  </fieldset>
                    ) : 
                    field.type === "date-picker" ? (
                      <fieldset class="form-filed col-md-6 ">
                        <FormsyDatePicker
                            name={field.name}
                            selectedDate={(additionalDetails && additionalDetails[field.name] && new Date(additionalDetails[field.name]))}
                            placeholder={field.label}
                            maxDate={field.maxDate}
                            minDate={new Date().setHours(0,0,0,0)}
                            value={additionalDetails && additionalDetails[field.name]}
                            onDateSelect={(date) => handleDateChange(date, field.name)}
                            dateFormat="dd MMM, yyyy"
                            validations={field.validations}
                            validationError={
                                additionalDetails[field.name] ? field.validationError : ""
                            }
                            required={field.required}
                            hasError={(additionalDetails.hasOwnProperty(field.name) && !additionalDetails[field.name]) ? true : false}
                            showAsterisk={field.showAsterisk}
                            readOnly={orgadditionalDetails?.[field.name] && isSubStatus3Marked}
                        />
                      {showAiIcon(field.name) ? <AiIcon /> : null}
                      </fieldset>
                    ) :
                    field.type === "dropdown" ? (
                    <fieldset class="single-select col-md-6">
                      <FormsySelect
                        name={field.name}
                        id={field.id}
                        inputProps={{
                            options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                            placeholder: field.label,
                            className: "react-select",
                            classNamePrefix: "react-select",
                            value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ value }) => value === (additionalDetails[field.name])),
                            isDisabled: field.readOnly || (orgadditionalDetails?.[field.name] && isSubStatus3Marked) ? true : false
                        }}
                        required={field.required}
                        showAsterisk={field.showAsterisk}
                        value={additionalDetails[field.name]}
                        onChange={(data) => handleSelectChange(data, field.name)}
                      />
                    {showAiIcon(field.name) ? <AiIcon /> : null}
                    </fieldset>
                    ) : null 
                ))}
                
              </div>

              <div className="btn-save-remarks">
              {isSubmitDisabled || !allowSubmit ||context.markAsFreezedNewFlow || accessCondition ?
                <span className="span-disabled">
                  Save & Next
                </span> :
                <button aria-label="save next" type="submit" className="btn-primary">
                  Save & Next
                </button>
               }
                <button aria-label="remarks history" type="button" className="btn-line" onClick={showModalViewTimeLine}>Remarks
                  <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                </button>
              </div>
            </Formsy>
          </div>
        </div>
        <div className="view-timeline-popup">
          <div className="view-timeline-popup">
            <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
              <div className="modal-header">
                <h2>Remark History</h2>
              </div>
              <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={context.markAsFreezedNewFlow}
              accessCondition = {accessCondition} sectionInfo = {sectionInfo} />
            </Modal>
          </div>
        </div>
      </div>
    );
}
export default AdditionalDetails;